          <div class="data-table pt-4 px-0 m-0 d-flex flex-column h-100">
            <div class="table-header border-bottom pb-4 row m-0 px-4">
              <div class="col-6 px-0 text-start">
                <h3 class="weight-700 text-gray-2 mb-1">Assigned Brands</h3>
                <p class="mx-0 my-0 text-sm">
                  Keep your business information up to date for the best results
                </p>
              </div>

            </div>
            <div class="row video-header mx-0">
              <ul
                class="container p-0 mb-1 d-flex justify-content-between align-items-center"
              >
                <li class="col-md-4 mb-2 pe-3">
                  <div class="formItm search-icon searchDropdown">
                    <input
                      id="search-feild"
                      type="search"
                      value=""
                      [(ngModel)]="searchTermText"
                      placeholder="Search brand"
                      required=""
                      (keyup.enter)="searchBrand()"
                    />
                    <label for="search-feild" class="text-sm" style="cursor: default"
                      >Search Brand</label
                    >

                    <img src="../../../../../assets/images/svg/search.svg" (click)="searchBrand()">
                  </div>
                  <div
                    class="searchtem"
                    *ngIf="searchTerm != ''"
                    (click)="clearSearchTerm()"
                  >
                    <div>{{ searchTerm }}</div>
                    <mat-icon class="link">close</mat-icon>
                  </div>
                </li>
                <li class="col-md-2 mb-2 ps-2" id="selectpreff">
                  <div class="formItm down_arrow me-1 mt-4 search-sort-panel">
                    <div class="dropdown dropdownBtn">
                      <button
                        class="dropdown-toggle text-md"
                        type="button"
                        (click)="openSearchSortTypeDD()"
                        (blur)="hideSearchSortTypeDD($event)"
                      >
                        <span class="dropdownBtnText"
                          ><strong>Sort by </strong>
                          {{ searchBy == "created" ? "Created" : "" }}
                          {{ searchBy == "_id" ? "Email" : "" }}
                          {{ searchBy == "name" ? "Name" : "" }}
                        </span>
                      </button>
                      <ul
                        class="dropdown-menu dropdown-menu-end dropdownMenu"
                        [ngClass]="{ showDropDown: showSortByDD }"
                      >
                        <li>
                          <a
                            class="dropdown-item text-md"
                            href="javascript:void(0)"
                            (click)="sortBySearchResult('name')"
                            >Name
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-md"
                            (click)="sortBySearchResult('created')"
                            >Last Created
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="sort-arrows pos-right">
                      <!-- <mat-icon *ngIf="sortType == -1" (click)="sortTypeSearch(1)"
                        >download</mat-icon
                      >
                      <mat-icon *ngIf="sortType == 1" (click)="sortTypeSearch(-1)"
                        >upload</mat-icon> -->

                      <img src="../../../../../assets/images/DataSort-1.svg" height="25px" *ngIf="sortType == -1" (click)="sortTypeSearch(1)">
                      <img src="../../../../../assets/images/DataSort-3.svg" height="25px"  *ngIf="sortType == 1" (click)="sortTypeSearch(-1)">
                    </div>
                    <!-- <select>
                                  <option selected>Last Modified</option>
                              </select> -->
                  </div>
                </li>

                <li class="col-md-4 mb-2">
                </li>
              </ul>
            </div>
            <div class="flex-grow-1 overflow-auto">
              <div class="my-3">
                <p>
                  <span class="text-lg">Total Brands: </span>
                  <b class="weight-600"> {{this.brandList?.result? this.brandList?.result.length : '0'}}/{{brandList?.total_count}}</b>

                </p>
              </div>
              <div class="overflow-auto overflow-table brandlist">
                <table class="table tableCustom table-hover mb-0">
                  <thead>
                    <tr>
                      <th scope="col" class="tableCheck">
                        <div class="custom-switch">
                          <input type="checkbox" class="check form-check-input" id="checkAll" />
                        </div>
                      </th>
                      <th scope="col" class="uname-width">Name</th>
                      <!-- <th scope="col">Website</th>
                      <th scope="col">Industry</th> -->
                      <th scope="col">Users</th>
                      <th scope="col">Published Videos</th>
                      <th scope="col" class="tableAction">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let brand of brandList?.result">
                      <td scope="row" class="tableCheck">
                        <div class="custom-switch">
                          <input type="checkbox" class="check form-check-input" />
                        </div>
                      </td>
                      <td>

                        <a class="b_userName" (click)="loadBrandProfile(brand._id)" href="javascript:void(0)">{{brand.name}}</a>
                      </td>
                      <!-- <td>{{brand.website}}</td>
                      <td>{{brand.industry}}</td> -->
                      <td>{{brand.user_count  ? brand.user_count : '0'}}</td>
                      <td>{{brand.published_video_count? brand.published_video_count: '0'}}</td>
                      <td class="tableAction">
                        <a href="javascript:void(0)" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                         <img class="svg-hover" src="../assets/images/icons/three-dots-elipses.svg">
                        </a>
                        <ul class="dropdown-menu add_location page-shadow" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <a class="dropdown-item text-md" href="#;"
                              [routerLink]="['/account-setting/manage-brand', brand._id.$oid,'business-details']">Brand Detail</a>
                          </li>
                          <!-- <li>
                            <a class="dropdown-item text-md"
                              [routerLink]="['/account-setting/manage-brand', brand._id.$oid, 'media-libraries']">View Media</a>
                          </li> -->
                          <li>
                            <a class="dropdown-item text-md"
                              [routerLink]="['/account-setting/manage-brand', brand._id.$oid, 'list-media-libraries']">Media Libraries</a>
                          </li>

                          <li>
                            <a class="dropdown-item text-md"
                              [routerLink]="['/account-setting/manage-brand', brand._id.$oid, 'published-videos']">Published Videos</a>
                          </li>



                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
            <div class="col-sm-12 text-end">
              <mat-paginator
              style="margin-top: 15px;"
              pageIndex="currentPage"
              [length]="brandList?.total_count"
              [pageSize]="limit"
              [pageSizeOptions]="[15, 20, 25, 50]"
              (page)="loadPageEvent($event)"
              showFirstLastButtons
            ></mat-paginator>
            </div>
          </div>

          </div>


          <!-- user filter drawer start -->
          <nz-drawer [nzClosable]="false" [nzVisible]="showFilterUserDrawer" [nzPlacement]="placementUser"
            (nzOnClose)="closeFilterUserDrawer()" [nzWidth]="478">
            <ng-container *nzDrawerContent>
              <nav>
                <!-- <nav id="filterAgencyUser" class="rightSideBar rightSideBarRight sidebar-block"> -->
                <div class="sidebar-header">
                  <h3 class="text-gray-2 weight-700 d-flex align-items-center">
                    Filters
                    <a href="javascript:void(0)" class="text-xs text-primary-4 weight-normal ms-3">Clear All</a>
                  </h3>
                  <div class="closeRightSideBar" (click)="closeFilterUserDrawer()">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                        fill="#F2F2F5" />
                      <path
                        d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                        fill="#A7ABB6" />
                    </svg>
                  </div>
                </div>
                <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
                  <div class="custom-select-box mb-4">
                    <h3 class="text-gray-2 text-lg mb-2">White Label</h3>
                    <div class="custom-switch">
                      <label>
                        <input type="checkbox" class="check form-check-input" />
                        First
                      </label>
                    </div>
                    <div class="custom-switch">
                      <label>
                        <input type="checkbox" class="check form-check-input" />
                        Last
                      </label>
                    </div>
                  </div>
                  <div class="custom-select-box mb-4">
                    <h3 class="text-gray-2 text-lg mb-2">Account Status</h3>
                    <div class="custom-switch">
                      <label>
                        <input type="checkbox" class="check form-check-input" />
                        Active
                      </label>
                    </div>
                    <div class="custom-switch">
                      <label>
                        <input type="checkbox" class="check form-check-input" />
                        Deactive
                      </label>
                    </div>
                  </div>
                </div>
                <div class="side-footer d-flex align-items-center">
                  <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-5">
                    <span class="py-1 d-block">Apply</span>
                  </button>
                </div>
              </nav>
            </ng-container>
          </nz-drawer>

          <!-- Modal pop-up Start-->
          <nz-modal
          [(nzVisible)]="isDeleteModalVisible"
          nzCentered
          [nzWidth]="566"
          [nzFooter]=null
          [nzClosable] = false
          (nzOnCancel)="handleCancel()"
          >

           <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <div class="modal-body">
                    <button type="button" class="btn-close cust-close closeRightBtn" (click)="handleCancel()"></button>

                    <h5>Are you sure you want to Delete this Brand?</h5>
                    <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">You'll not be able to watch this Brand again.
                        </p>
                    <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="handleCancel()">Cancel</a>
                  <a href="javascript:void(0)"
                         class="mt-3 text-gray-3 text-sm" (click)="deleteBrand()">Delete</a>
                </div>
            </div>
          </div>
          </nz-modal>
          <!-- user filter drawer end -->

          <!-- <div class="overlay sidebarOverlay"></div>
          <div class="overlay sidebarOverlay2"></div> -->