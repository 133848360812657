<nav>
    <div class="sidebar-header" style="padding: 3px 20px; overflow-x: hidden;">
        <h3 class="text-gray-2 weight-400 text-lg pt-2">
            Add Voiceover
            <p class="mb-0 mt-1 text-xs text-gray-3" style="font-family: Poppins;">Upload or choose from our diverse voice avatars.</p>
        </h3>
        <!-- <a href="javascript:void(0)" (click)="VideoTimelineView_TurnOffVoiceOver()"
            *ngIf="this.videoManager?.selectedVideo?.vo_text && this.videoManager?.selectedVideo?.vo_text != ''"
            class="btn text-xs d-inline-block btn-outline-gray-radius text-gray-3 btn-change">Turn Off Voiceover</a> -->

        <div class="closeRightSideBar pe-2" style="top: 0;" (click)="closeVoiceover()">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                        fill="#7A7A7A"></path>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="11.8" height="11.8" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>
        </div>

        <!-- <div class="d-flex justify-content-start mt-3 ms-1">
            <input type="checkbox" [checked]="this.isVoiceMuted" (change)="muteVoice($event)">
            <p class="text-secondary m-0 ms-2">Mute Voice</p>
        </div> -->
    </div>

    <!-- <ul class="nav breadcrumb-tabs ms-3 mb-0" id="myTab" role="tablist">
        <li class="nav-item ps-0" role="presentation">
          <button class="nav-link active tab-size ps-0" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1" type="button"
            role="tab" aria-controls="tab1" aria-selected="true" (click)="selectTab('uploadVoice')">
            Upload Voiceover
          </button>
        </li>
        <li class="nav-item px-0" role="presentation">
          <button class="nav-link tab-size" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#tab2" type="button"
            role="tab" aria-controls="tab2" aria-selected="false" (click)="selectTab('selectVoice')">
            Select Voice
          </button>
        </li>
      </ul> -->
    <!-- <div class="side-content flex-grow-1 pt-0" style="padding: 0px 14px !important; border-top: 1px solid #f2f2f5 !important;"  id="myTabContent">
        <div *ngIf="this.uploadVoice" class=" tab-pane mt-3"  id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
        <button (click)="ContentComponent_ToggleFileUploadType(true)" class="btnFileUpload btn-secondary btn-block"
            style="position: relative;">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.18253 5.2881C4.36857 5.49249 4.67022 5.49249 4.85626 5.2881L8.01029 1.82314L8.0122 9.30108C8.01227 9.5773 8.23654 9.80116 8.51312 9.80107C8.7897 9.80098 9.01385 9.57699 9.01378 9.30077L9.01186 1.82327L12.1658 5.2881C12.3518 5.49249 12.6535 5.49249 12.8395 5.2881C13.0255 5.08371 13.0255 4.75233 12.8395 4.54794L8.84788 0.162813C8.66183 -0.0415756 8.36019 -0.0415758 8.17415 0.162813L4.18253 4.54794C3.99648 4.75233 3.99648 5.08371 4.18253 5.2881ZM1.50096 7.2379C1.50096 6.96167 1.27675 6.73775 1.00018 6.73775C0.7236 6.73775 0.49939 6.96167 0.49939 7.2379V8.48817C0.49939 12.6315 3.86255 15.9904 8.0112 15.9904H9.01477C13.1634 15.9904 16.5266 12.6315 16.5266 8.48817V7.2379C16.5266 6.96167 16.3024 6.73775 16.0258 6.73775C15.7492 6.73775 15.525 6.96167 15.525 7.2379V8.48817C15.525 12.0791 12.6103 14.9901 9.01477 14.9901H8.0112C4.4157 14.9901 1.50096 12.0791 1.50096 8.48817V7.2379Z"
                    fill="white" />
            </svg>
            <span class="text-sm">Upload a File</span>
            <input #file class="upload-file" type="file" accept="audio/mp3,audio/mp4" name="myfile"
                (change)="ContentComponent_UploadedVoiceOverFile($event)">
        </button>
        <div class="text-center my-3 text-xs text-gray-3" >
            Or
        </div>

        <div class="media_list" [ngClass]="nVoiceOverFileSize!==0?'d-block':'d-none'">
            <ul>
                <li class="mb-3">
                    <figure class="media_figure">
                        <span class="media">
                            <img src="../assets/images/business_Profile/audio-line.png" alt="vo-file"></span>
                        <figcaption>
                            <div class="caption w-100">
                                <p class="text-xs sub_title">{{sUploadedVoiceOverFileName | slice:0:30}}</p>
                                <p class="text-xs sub_title"> {{ContentComponent_ReturnFileSize(nVoiceOverFileSize)}}
                                </p>
                            </div>
                        </figcaption>
                    </figure>
                </li>
            </ul>
        </div>

        <label class="text-lg text-gray-2 mb-3" href="javascript:void(0)"
            >Enter text for Voiceover</label>


        <div class="formItm" >
            <input id="voice_over_text" [(ngModel)]="va_text" type="text" placeholder="Enter your text" required="">
            <label class="text-sm" for="voice_over_text">Enter your text</label>
        </div>
        <div class="mb-3"

        >
            <label class="text-sm text-gray-3">Select type of voice</label>
            <div class="d-flex mt-3">
                <label class="customCheck customCheckRounder">
                    <input type="radio" class="check form-check-input" name="voiceType"
                        [checked]="sSelectedVoiceGender==='male'?true:false"
                        (click)="ContentComponent_SelectVoiceGender('male','create')" />
                    <span class="customCheckCheck"></span>
                    <span class="customCheckText text-gray-1 text-md">Male</span>
                </label>
                <label class="customCheck customCheckRounder ms-4">
                    <input type="radio" class="check form-check-input" name="voiceType"
                        [checked]="sSelectedVoiceGender==='female'?true:false"
                        (click)="ContentComponent_SelectVoiceGender('female','create')" />
                    <span class="customCheckCheck"></span>
                    <span class="customCheckText text-gray-1 text-md">Female</span>
                </label>
            </div>
        </div>
        <div

        >
            <div class="dropdown dropdownSelect">
                <label class="text-sm text-gray-3">Choose Voice</label>
                <button class="dropdown-toggle text-md" style="color: black" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {{this.sVoiceName.slice(0, 5) + ' '.repeat(3) + this.sVoiceName.slice(5) | titlecase}}
                </button>
                <ul class="dropdown-menu dropdownMenu">
                    <li *ngFor="let item of oVoiceOverList;let i=index;" [class.active]="i == nSelectedVoiceIndex"
                        (click)="ContentComponent_SelectVoice(item,i); VoiceOVerAdded()">
                        <a class="dropdown-item text-md d-flex justify-content-between"
                            (mouseover)="ContentComponent_PlayAudio(item.sample_voice)"
                            (mouseout)="ContentComponent_StopAudio(item.sample_voice)">{{item.name.slice(0, 5) + '
                            '.repeat(3) + item.name.slice(5) | titlecase}}
                            <span class="play_staus audio-play-status"
                                [ngClass]="i == nSelectedVoiceIndex ? 'd-none' : 'd-block'">
                                <span class="play">
                                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.666992 7.08335C0.666992 7.60239 1.04444 7.81494 1.50594 7.55599L6.98752 4.47209C7.44904 4.21256 7.44904 3.78748 6.98752 3.52796L1.50594 0.443766C1.04441 0.184243 0.666992 0.396502 0.666992 0.916401V7.08335Z"
                                            fill="#2B2F32" />
                                    </svg>
                                </span>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="this.selectVoice" class="tab-pane"  id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
        we are here ...
    </div>
    </div> -->
    <app-video-voice-over-selection (updateVoiceRecords)="updateVoiceRecords($event)" [editVoicePanel]="this.editVoicePanel"></app-video-voice-over-selection>
    <!-- toast message -->
    <div *ngIf="isVoiceoverAdded" class=" alert alert-primary d-flex align-items-center alert-dismissible fade show mt-5 mx-4" role="alert">
        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 29C21.732 29 28 22.5081 28 14.5C28 6.49187 21.732 0 14 0C6.26801 0 0 6.49187 0 14.5C0 22.5081 6.26801 29 14 29Z" fill="#66AFD9" />
            <path d="M13.9992 17.0373C14.5732 17.0373 15.0492 16.5443 15.0492 15.9498V8.6998C15.0492 8.1053 14.5732 7.6123 13.9992 7.6123C13.4252 7.6123 12.9492 8.1053 12.9492 8.6998V15.9498C12.9492 16.5443 13.4252 17.0373 13.9992 17.0373Z" fill="white" />
            <path
                d="M15.2876 19.7492C15.2176 19.5752 15.1196 19.4157 14.9936 19.2707C14.8536 19.1402 14.6996 19.0387 14.5316 18.9662C14.1956 18.8212 13.8036 18.8212 13.4676 18.9662C13.2996 19.0387 13.1456 19.1402 13.0056 19.2707C12.8796 19.4157 12.7816 19.5752 12.7116 19.7492C12.6416 19.9232 12.5996 20.1117 12.5996 20.3002C12.5996 20.4887 12.6416 20.6772 12.7116 20.8512C12.7816 21.0397 12.8796 21.1847 13.0056 21.3297C13.1456 21.4602 13.2996 21.5617 13.4676 21.6342C13.6356 21.7067 13.8176 21.7502 13.9996 21.7502C14.1816 21.7502 14.3636 21.7067 14.5316 21.6342C14.6996 21.5617 14.8536 21.4602 14.9936 21.3297C15.1196 21.1847 15.2176 21.0397 15.2876 20.8512C15.3576 20.6772 15.3996 20.4887 15.3996 20.3002C15.3996 20.1117 15.3576 19.9232 15.2876 19.7492Z"
                fill="white" />
        </svg>
        <div class="ms-2 me-8">Voiceover added to video. Don't forget to generate your video to reflect the changes.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                        fill="#FF8369" />
                </svg>
            </button>
        </div>
    </div>
    <div class="ps-3" style="position:absolute; bottom:3%;">
        <button class=" btn btn-primary btn-round-6 d-inline-block small-pad px-4 closeSideBar" (click)="saveVoiceDate()">Save</button>
    </div>
</nav>