import { ContentTextLayersMerger } from './services/pipes/context-text-layers-merger';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/on-boarding/login/login.component';
import { ForgotPasswordComponent } from './components/on-boarding/forgot-password/forgot-password.component';
import { CreateAccountComponent } from './components/on-boarding/create-account/create-account.component';
import { SorryComponent } from './components/on-boarding/sorry/sorry.component';
import { ResetPasswordComponent } from './components/on-boarding/reset-password/reset-password.component';
import { CreateAccountCompanyComponent } from './components/on-boarding/create-account-company/create-account-company.component';
// import { AccountSidebarComponent } from './shared/account-sidebar/account-sidebar.component';
import { HeaderComponent } from './shared/header/header.component';
import { cWebSocketService } from './services/websocket.service';
import { cClientService } from './services/client.service';
import { cDataService } from './services/data.service';
import { AuthenticationComponent } from './components/on-boarding/authentication/authentication.component';
import { AuthenticationCodeComponent } from './components/on-boarding/authentication-code/authentication-code.component';
import { BusinessSidebarComponent } from './shared/business-sidebar/business-sidebar.component';

import { MyvideosSidebarComponent } from './shared/myvideos-sidebar/myvideos-sidebar.component';
import { OverviewComponent } from './components/video-wizard-view/overview/overview.component';
import { LooknFeelComponent } from './components/video-wizard-view/looknfeel/looknfeel.component';
import { CreateVideoHeaderComponent } from './shared/create-video-header/create-video-header.component';
import { CreateVideoBreadcrumbComponent } from './shared/create-video-breadcrumb/create-video-breadcrumb.component';
import { ContentComponent } from './components/video-wizard-view/content/content.component';
import { OtherSettingComponent } from './components/video-wizard-view/other-setting/other-setting.component';
import { EditVideoContentComponent } from './components/edit-video/wizard-view/edit-video-content/edit-video-content.component';
import { EditVideoOtherSettingComponent } from './components/edit-video/wizard-view/edit-video-other-setting/edit-video-other-setting.component';
import { EditVideoOverviewComponent } from './components/edit-video/wizard-view/edit-video-overview/edit-video-overview.component';
import { EditVideoHeaderComponent } from './shared/edit-video-header/edit-video-header.component';
import { EditVideoBreadcrumbComponent } from './shared/edit-video-breadcrumb/edit-video-breadcrumb.component';
import { EditVideoSidepanelsComponent } from './shared/edit-video-sidepanels/edit-video-sidepanels.component';
import { PreviewLocaleComponent } from './components/edit-video/wizard-view/preview-locale/preview-locale.component';
import { SocialChannelsComponent } from './components/social-channels/social-channels.component';
import { DraftVideosComponent } from './components/dashboard-videos/draft-videos/draft-videos.component';
import { PublishedVideosComponent } from './components/dashboard-videos/published-videos/published-videos.component';
import { ScheduledVideosComponent } from './components/dashboard-videos/scheduled-videos/scheduled-videos.component';
import { PreviewComponent } from './components/video-wizard-view/preview/preview.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardMyvideoComponent } from './components/dashboard/dashboard-myvideo/dashboard-myvideo.component';
import { DashboardSchduledVideosComponent } from './components/dashboard/dashboard-schduled-videos/dashboard-schduled-videos.component';
import { DashboardBreadcrumbComponent } from './shared/dashboard-breadcrumb/dashboard-breadcrumb.component';
import { DashboardSidepanelsComponent } from './shared/dashboard-sidepanels/dashboard-sidepanels.component';
import { BrandIdentityComponent } from './components/business-profile/brand-identity/brand-identity.component';
import { UserMediaComponent } from './components/business-profile/user-media/user-media.component';
import { CustomersExperienceComponent } from './components/business-profile/customers-experience/customers-experience.component';
import { SocialsChannelsComponent } from './components/business-profile/socials-channels/socials-channels.component';
import { ReportsReachComponent } from './components/reports/reports-reach/reports-reach.component';
import { ReportsEngagementComponent } from './components/reports/reports-engagement/reports-engagement.component';
import { ReportsAudienceComponent } from './components/reports/reports-audience/reports-audience.component';
import { ReportsBreadcrumbComponent } from './shared/reports-breadcrumb/reports-breadcrumb.component';
import { ReportsSidebarVideosComponent } from './shared/reports-sidebar-videos/reports-sidebar-videos.component';
import { DashboardSocialmediaComponent } from './components/dashboard/dashboard-socialmedia/dashboard-socialmedia.component';
import { DashboardDownloadComponent } from './components/dashboard/dashboard-download/dashboard-download.component';
import { DashboardOverviewComponent } from './components/dashboard/dashboard-overview/dashboard-overview.component';
import { DashboardHeaderComponent } from './shared/dashboard-header/dashboard-header.component';
import { EditVideoLookFeelComponent } from './components/edit-video/wizard-view/edit-video-look-feel/edit-video-look-feel.component';
import { UserComponent } from './components/business-profile/user/user.component';
import { UserAccountComponent } from './components/business-profile/user/user-account/user-account.component';
import { UserPublishedVideoComponent } from './components/business-profile/user/user-published-video/user-published-video.component';
import { UserManageLibrariesComponent } from './components/business-profile/user/user-manage-libraries/user-manage-libraries.component';
import { MediaLibraryComponent } from './components/business-profile/media-library/media-library.component';
import { BrandsComponent } from './components/business-profile/brands/brands.component';
import { BrandsAccountComponent } from './components/business-profile/brands/brands-account/brands-account.component';
import { BrandsBusinessDetailsComponent } from './components/business-profile/brands/brands-business-details/brands-business-details.component';
import { BrandsPublishedVideoComponent } from './components/business-profile/brands/brands-published-video/brands-published-video.component';
import { BrandsMediaLibraryComponent } from './components/business-profile/brands/brands-media-library/brands-media-library.component';
import { FranchiseesComponent } from './components/business-profile/franchisees/franchisees.component';
import { FrancheseesAccountComponent } from './components/business-profile/franchisees/franchesees-account/franchesees-account.component';
import { FrancheseesPublishedVideoComponent } from './components/business-profile/franchisees/franchesees-published-video/franchesees-published-video.component';
import { FrancheseesMediaLibraryComponent } from './components/business-profile/franchisees/franchesees-media-library/franchesees-media-library.component';
import { EnterpriseMediaComponent } from './components/business-profile/enterprise-media/enterprise-media.component';
import { EnterpriseMediaLibraryComponent } from './components/business-profile/enterprise-media-library/enterprise-media-library.component';
import { TimelineViewProcessComponent } from './components/published-video/timeline-view-process/timeline-view-process.component';
import { TimelineViewProntoComponent } from './components/published-video/timeline-view-pronto/timeline-view-pronto.component';
import { TimelineViewProntoShareComponent } from './components/published-video/timeline-view-pronto-share/timeline-view-pronto-share.component';
import { TimelineViewComponent } from './components/published-video/timeline-view/timeline-view.component';
import { VideoTimelineComponent } from './components/edit-video/timeline-view/video-timeline/video-timeline.component';
import { TimelineViewSidepanelsComponent } from './shared/timeline-view-sidepanels/timeline-view-sidepanels.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgWaveformModule } from 'ng-waveform';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MediaLibrarySharedComponent } from './shared/media-library-shared/media-library-shared.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { FirstTimeDashboardComponent } from './components/dashboard/first-time-dashboard/first-time-dashboard.component';
import { SafePipe } from './services/pipes/safe.pipe';
import { RemoveAudioFromVisualData } from './services/pipes/remove-audio-from-visual-data.pipe';
// import { SecondsToTime } from './services/pipes/seconds-to-time.pipe';
import { ManageCommentsComponent } from './components/dashboard/manage-comments/manage-comments.component';
import { BusinessProfileSidepanelsComponent } from './shared/business-profile-sidepanels/business-profile-sidepanels.component';
import { SelectPlanComponent } from './components/on-boarding/select-plan/select-plan.component';
import { PaywallPaymentFailedComponent } from './components/on-boarding/paywall-payment-failed/paywall-payment-failed.component';
import { PaywallPaymentSuccessComponent } from './components/on-boarding/paywall-payment-success/paywall-payment-success.component';
import { CompletePaymentComponent } from './components/on-boarding/complete-payment/complete-payment.component';
import { OnboardingAsideSectionComponent } from './shared/onboarding-aside-section/onboarding-aside-section.component';
import { EmptyReportsComponent } from './shared/empty-reports/empty-reports.component';
import { CreatedVideosComponent } from './shared/created-videos/created-videos.component';
import { ScheduledPopupComponent } from './shared/scheduled-popup/scheduled-popup.component';
import { DatePipe } from '@angular/common';
import { SchuledVideosComponent } from './shared/schuled-videos/schuled-videos.component';
import { PublishVideoTimelineViewFunFectComponent } from './components/published-video/publish-video-timeline-view-fun-fect/publish-video-timeline-view-fun-fect.component';
import { MediaLibraryPopupComponent } from './shared/media-library-popup/media-library-popup.component';
import { AudioSidepanelComponent } from './shared/audio-sidepanel/audio-sidepanel.component';
import { BlockSidepanelComponent } from './shared/block-sidepanel/block-sidepanel.component';
import { VoiceoverSidepanelComponent } from './shared/voiceover-sidepanel/voiceover-sidepanel.component';
import { CommentSectionComponent } from './components/published-video/comment-section/comment-section.component';
import { PublicVideosComponent } from './components/public-videos/public-videos.component';
import { PublicPageComponent } from './components/published-video/public-page/public-page.component';
import { FtueOverviewComponent } from './components/first-time-user-experience/ftue-overview/ftue-overview.component';
import { FtueLooknfeelComponent } from './components/first-time-user-experience/ftue-looknfeel/ftue-looknfeel.component';
import { FtueContentComponent } from './components/first-time-user-experience/ftue-content/ftue-content.component';
import { FtueOtherSettingComponent } from './components/first-time-user-experience/ftue-other-setting/ftue-other-setting.component';
import { FtuePreviewComponent } from './components/first-time-user-experience/ftue-preview/ftue-preview.component';
import { FtueLoginComponent } from './components/first-time-user-experience/ftue-login/ftue-login.component';
import { VerifyResetPasswordComponent } from './components/verify-reset-password/verify-reset-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { SupportComponent } from './components/on-boarding/support/support.component';
import { FtueCreateVideoBreadcrumbComponent } from './shared/ftue-create-video-breadcrumb/ftue-create-video-breadcrumb.component';


import { TextSidepanelComponent } from './shared/text-sidepanel/text-sidepanel.component';
import Cohere from 'cohere-js';
import { cSessionService } from './services/session.service';

import { TimeoutErrorComponent } from './shared/timeout-error/timeout-error.component';
import { LocationVideosComponent } from './components/dashboard-videos/location-videos/location-videos.component';
import { LocationsCreatedVideosComponent } from './shared/locations-created-videos/locations-created-videos.component';

import { PhoneMaskDirective } from '././directive/phone-mask.directive';
import { ConfirmResetPasswordComponent } from './components/on-boarding/confirm-reset-password/confirm-reset-password.component';
import { PasswordResetSuccessMessageComponent } from './components/on-boarding/password-reset-success-message/password-reset-success-message.component';
import { VerifyEmailMessageComponent } from './components/on-boarding/verify-email-message/verify-email-message.component';

import en from '@angular/common/locales/en';

import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { AlertModule } from './_alert';
import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { DashboardVideosComponent } from './components/dashboard-videos/dashboard-videos.component';
import { Httpinterceptor } from './interceptor/httpinterceptor.service';
import { BusinessProfileComponent } from './components/business-profile/business-profile.component';
import { CreateVideoWizardComponent } from './components/video-wizard-view/create-video-wizard.component';
import { VideoSidepanelComponent } from './shared/video-sidepanel/video-sidepanel.component';
import { FtCreateVideoWizardViewComponent } from './components/ft-create-video-wizard-view/ft-create-video-wizard-view.component';
import { FtContentComponent } from './components/ft-create-video-wizard-view/ft-content/ft-content.component';
import { FtLooknfeelComponent } from './components/ft-create-video-wizard-view/ft-looknfeel/ft-looknfeel.component';
import { FtOtherSettingComponent } from './components/ft-create-video-wizard-view/ft-other-setting/ft-other-setting.component';
import { FtOverviewComponent } from './components/ft-create-video-wizard-view/ft-overview/ft-overview.component';
import { FtPreviewComponent } from './components/ft-create-video-wizard-view/ft-preview/ft-preview.component';
import { FtLoginComponent } from './components/ft-create-video-wizard-view/ft-login/ft-login.component';
import { SplashscreenComponent } from './shared/splashscreen/splashscreen.component';
import { TimelineItemComponent } from './shared/timeline-item/timeline-item.component';
import { DragDirective } from './directive/drag.directive';
import { LocaliseExistingVideosComponent } from './components/localise-existing-videos/localise-existing-videos.component';
import { SingleVideoComponent } from './components/localise-existing-videos/single-video/single-video.component';
import { MultipleVideosComponent } from './components/localise-existing-videos/multiple-videos/multiple-videos.component';
import { ModifyVideoTimelineComponent } from './components/modify-video/sap-video-timeline/sap-video-timeline.component';
import { SapVideoWizardComponent } from './components/modify-video/modify-video-wizard/sap-video-wizard.component';
import { SapContentComponent } from './components/modify-video/modify-video-wizard/sap-content/sap-content.component';
import { MvLooknfeelComponent } from './components/modify-video/modify-video-wizard/sap-looknfeel/mv-looknfeel.component';
import { MvOtherSettingComponent } from './components/modify-video/modify-video-wizard/sap-other-setting/mv-other-setting.component';
import { MvOverviewComponent } from './components/modify-video/modify-video-wizard/sap-overview/sap-overview.component';
import { ModifyVideo } from './models/modify-video';
import { ModifyVideoTimelineViewSidepanelsComponent } from './shared/modify-video-timeline-view-sidepanels/modify-video-timeline-view-sidepanels.component';
import { SapPreviewComponent } from './components/modify-video/modify-video-wizard/sap-preview/sap-preview.component';
import { ModifyVideoTextSidepanelComponent } from './shared/modify-video-text-sidepanel/modify-video-text-sidepanel.component';
import { ModifyVideoReplacementClipSidepanelComponent } from './shared/modify-video-replacement-clip-sidepanel/modify-video-replacement-clip-sidepanel.component';
import { ModifyVideoVoiceoverSidepanelComponent } from './shared/modify-video-voiceover-sidepanel/modify-video-voiceover-sidepanel.component';
import { ModifyVideoSettingSidepanelComponent } from './shared/modify-video-setting-sidepanel/modify-video-setting-sidepanel.component';
import { MousepositiontrackDirective } from './directive/mousepositiontrack.directive';
import { ModifyVideoAudioSidepanelComponent } from './shared/modify-video-audio-sidepanel/modify-video-audio-sidepanel.component';
import { DragDropFileUploadDirective } from './shared/directives/drag-drop-file-upload.directive';
import { UploadFileDialogComponent } from './shared/components/upload-file-dialog/upload-file-dialog.component';
import { BrandLogoListComponent } from './shared/brand-logo-list/brand-logo-list.component';
import { FontNameTruncate } from './services/pipes/font-file-name-truncate.pipe';
import { EmailVerificationComponent } from './components/on-boarding/email-verification/email-verification.component';
import { environment } from 'src/environments/environment';
import { SystemFontsDropdownComponent } from './shared/components/system-fonts-dropdown/system-fonts-dropdown.component';
import { SapTaglineSidepanelComponent } from './shared/sap-tagline-sidepanel/sap-tagline-sidepanel.component';
import { AspectRatioPopupComponent } from './shared/components/aspect-ratio-popup/aspect-ratio-popup.component';
import { SharedModule } from './shared/shared.module';
import { ManageBrandAccountComponent } from './components/myAccount/manage-brand/account/manage-brand-account.component';
import { ManageBrandsBusinessDetailsComponent } from './components/myAccount/manage-brand/business-detail/manage-brands-business-details.component';
import { LoginSecurityComponent } from './components/myAccount/login-security/login-security.component';
import { SubscriptionComponent } from './components/myAccount/subscription/subscription.component';
import { RemoveEmptyTextValues } from './services/pipes/removeEmptyTextValues.pipe';
import { ChangeTitleOfVideoText } from './services/pipes/change-title-of-video-text.pipe';
import { AddKeywordsComponent } from './shared/components/add-keywords/add-keywords.component';
// import { VoiceOverSingleItemComponent } from './shared/components/voice-over-single-item/voice-over-single-item.component';
// import { SearchVoiceOverPipe } from './shared/pipes/search-voice-over.pipe';
import { MediaEditorPopupComponent } from './shared/media-editor-popup/media-editor-popup.component';
import { MyAccountModule } from './components/myAccount/my-account.module';
import { VoiceEditorPopupComponent } from './shared/voice-editor-popup/voice-editor-popup.component';
import { VideoDetailComponent } from './components/dashboard/video-detail/video-detail.component';
import { LocationsListComponent } from './shared/locations-list/locations-list.component';
registerLocaleData(en);

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);
// Cohere.init("F-VgJMh1R2bkmh8YXdf9NmEs");
if (environment.ACTIVE_COHERE) {
    Cohere.init('BveZ-3wdzATdunLAMkcQ0VeY');
}

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent,
        LoginComponent,
        ForgotPasswordComponent,
        CreateAccountComponent,
        SorryComponent,
        ResetPasswordComponent,
        // BillingComponent,
        LoginSecurityComponent,
        SubscriptionComponent,
        CreateAccountCompanyComponent,
        // AccountSidebarComponent,
        // HeaderComponent,
        AuthenticationComponent,
        AuthenticationCodeComponent,
        BusinessSidebarComponent,
        PublishedVideosComponent,
        DraftVideosComponent,
        MyvideosSidebarComponent,
        OverviewComponent,
        LooknFeelComponent,
        CreateVideoHeaderComponent,
        CreateVideoBreadcrumbComponent,
        ContentComponent,
        OtherSettingComponent,
        EditVideoContentComponent,
        EditVideoOtherSettingComponent,
        EditVideoOverviewComponent,
        EditVideoHeaderComponent,
        EditVideoBreadcrumbComponent,
        EditVideoSidepanelsComponent,
        PreviewLocaleComponent,
        SocialChannelsComponent,
        ScheduledVideosComponent,
        PreviewComponent,
        FooterComponent,
        DashboardMyvideoComponent,
        DashboardSchduledVideosComponent,
        DashboardBreadcrumbComponent,
        DashboardSidepanelsComponent,
        BrandIdentityComponent,
        UserMediaComponent,
        CustomersExperienceComponent,
        SocialsChannelsComponent,
        ReportsReachComponent,
        ReportsEngagementComponent,
        ReportsAudienceComponent,
        ReportsBreadcrumbComponent,
        ReportsSidebarVideosComponent,
        DashboardSocialmediaComponent,
        DashboardDownloadComponent,
        DashboardOverviewComponent,
        DashboardHeaderComponent,
        EditVideoLookFeelComponent,
        UserComponent,
        UserAccountComponent,
        UserPublishedVideoComponent,
        UserManageLibrariesComponent,
        MediaLibraryComponent,
        BrandsComponent,
        BrandsAccountComponent,
        BrandsBusinessDetailsComponent,
        BrandsPublishedVideoComponent,
        BrandsMediaLibraryComponent,
        FranchiseesComponent,
        FrancheseesAccountComponent,
        FrancheseesPublishedVideoComponent,
        FrancheseesMediaLibraryComponent,
        EnterpriseMediaComponent,
        EnterpriseMediaLibraryComponent,
        TimelineViewProcessComponent,
        TimelineViewProntoComponent,
        TimelineViewProntoShareComponent,
        TimelineViewComponent,
        VideoTimelineComponent,
        // TimelineViewSidepanelsComponent,
        TimelineItemComponent,
        // MediaLibrarySharedComponent,
        // MediaLibrarySharedMyAccountComponent,
        // LoaderComponent,
        FirstTimeDashboardComponent,
        SafePipe,
        // RemoveAudioFromVisualData,
        // SecondsToTime,
        RemoveEmptyTextValues,
        ChangeTitleOfVideoText,
        FontNameTruncate,
        ContentTextLayersMerger,
        ManageCommentsComponent,
        BusinessProfileSidepanelsComponent,
        SelectPlanComponent,
        PaywallPaymentFailedComponent,
        PaywallPaymentSuccessComponent,
        CompletePaymentComponent,
        OnboardingAsideSectionComponent,
        EmptyReportsComponent,
        // CreatedVideosComponent,
        ScheduledPopupComponent,
        SchuledVideosComponent,
        PublishVideoTimelineViewFunFectComponent,
        // MediaLibraryPopupComponent,
        AudioSidepanelComponent,
        BlockSidepanelComponent,
        VoiceoverSidepanelComponent,
        CommentSectionComponent,
        PublicVideosComponent,
        PublicPageComponent,
        FtueOverviewComponent,
        FtueLooknfeelComponent,
        FtueContentComponent,
        FtueOtherSettingComponent,
        FtuePreviewComponent,
        FtueLoginComponent,
        VerifyResetPasswordComponent,
        VerifyEmailComponent,
        SupportComponent,
        FtueCreateVideoBreadcrumbComponent,
        // BrandsIdentityComponent,
        // LocationsComponent,
        // SociallChannelsComponent,
        // StorageDetailsComponent,
        // PublisheddVideoComponent,
        // MediaaLibrariesComponent,
        // MediaLibraryyComponent,
        TextSidepanelComponent,
        // MediaPlatformsComponent,
        // UsersComponent,
        // UserAccountsComponent,
        // UserAccountPublishedVideosComponent,
        // UserAccountScheduleVideoComponent,
        // UserAccountMediaLibrariesComponent,
        TimeoutErrorComponent,
        // ManageBrandComponent,
        // BrandMediaLibsComponent,
        ManageBrandAccountComponent,
        // ManageBrandPublishedVideosComponent,
        // ManageBrandScheduleVideosComponent,
        // ManageBrandMediaLibrariesComponent,
        LocationVideosComponent,
        LocationsCreatedVideosComponent,
        PhoneMaskDirective,
        ConfirmResetPasswordComponent,
        PasswordResetSuccessMessageComponent,
        VerifyEmailMessageComponent,
        // AccountSettingComponent,
        DashboardVideosComponent,
        BusinessProfileComponent,
        CreateVideoWizardComponent,
        // VideoSidepanelComponent,
        FtCreateVideoWizardViewComponent,
        FtContentComponent,
        FtLooknfeelComponent,
        FtOtherSettingComponent,
        FtOverviewComponent,
        FtPreviewComponent,
        FtLoginComponent,
        // SplashscreenComponent,
        DragDirective,
        LocaliseExistingVideosComponent,
        SingleVideoComponent,
        MultipleVideosComponent,
        ModifyVideoTimelineComponent,
        SapVideoWizardComponent,
        MvOverviewComponent,
        MvLooknfeelComponent,
        SapContentComponent,
        MvOtherSettingComponent,
        ModifyVideoTimelineViewSidepanelsComponent,
        SapPreviewComponent,
        ModifyVideoTextSidepanelComponent,
        ModifyVideoReplacementClipSidepanelComponent,
        ModifyVideoVoiceoverSidepanelComponent,
        ModifyVideoSettingSidepanelComponent,
        MousepositiontrackDirective,
        ModifyVideoAudioSidepanelComponent,
        // PrVideoPlayerComponent,
        // ManageBrandAccountComponent,
        ManageBrandsBusinessDetailsComponent,
        DragDropFileUploadDirective,
        UploadFileDialogComponent,
        // SuccessMessageComponent,
        // FormatFileSizePipe,
        // BrandLogoListComponent,
        EmailVerificationComponent,
        // SystemFontsDropdownComponent,
        // FontFileNameTruncatePipe,
        SapTaglineSidepanelComponent,
        AspectRatioPopupComponent,
        // TimestampToDate,
        // AddKeywordsComponent,
        // VoiceOverSingleItemComponent,
        // BrowseVoiceOverComponent,
        // VoiceOverFilterSearchWizardComponent,
        // MyAccountVoiceOverComponent,
        // SearchVoiceOverPipe,
        MediaEditorPopupComponent,
        VoiceEditorPopupComponent,
        VideoDetailComponent,
        // AddKeywordsComponent,
        LocationsListComponent
    ],
    exports: [PhoneMaskDirective, LoaderComponent, OnboardingAsideSectionComponent],
    imports: [
        SharedModule,
        MyAccountModule,
        // DatesPipeModule,
        AppRoutingModule
    ],
    providers: [
        ModifyVideo,
        DatePipe,
        cClientService,
        cWebSocketService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Httpinterceptor,
            multi: true,
        },
        cDataService,
        CookieService,
        { provide: NZ_I18N, useValue: en_US },
        { provide: NZ_ICONS, useValue: icons },
    ],
    bootstrap: [AppComponent],

})
export class AppModule {
    sUsername: any;
    userDetails: object;
    sSessionId: any;
    sUserEmail: string;
    constructor(private SessionService: cSessionService, private DataService: cDataService) {
        this.DataService.oGetUserDetails.subscribe((res) => {
            this.sUserEmail = res.email;
            this.sSessionId = res.session_id;
            this.sUsername = res.name;
            if (environment.ACTIVE_COHERE) {
                Cohere.identify(
                    '<user_id>', // Required: can be any unique ID
                    {
                        displayName: this.sUsername, // Optional
                        email: this.sUserEmail, // Optional
                    },
                );
            }
        });
    }
}
