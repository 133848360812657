// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment =
{
  production: false,
  BULK_UPLOAD_USING_AJAX: 0,
  PRONTO_USER_SESSION_TIMEOUT: 7200000, // 2 Hours
  API_HOST_ADMIN: '/admin/',
  API_HOST: '/app/',
  WS_HOST: 'https://azstg.prontopia.com',
  WS_PATH: '/ws',
  SHOW_SAP : true,
  SHOW_SAP_FOR_SMB : false,
  ACTIVE_COHERE: false,
  ACTIVE_HUBSPOT: false,
  IS_AZURE : true,
  // for business plans
  business_plan : 'business',
  bDisplayLocales: true,
  // business_plan : 'business_plus',
  // business_plan : 'enterprise_agency',
  // business_plan : 'enterprise_franchesees',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
