import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { profile } from './../../../models/onboarding';
import { cDataService } from './../../../services/data.service';
import { ErrorService } from './../../../services/error.service';
import { cSessionService } from './../../../services/session.service';


@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  fCreateAccountform: FormGroup
  sGoogleSigninLink: string;
  sFacebookSigninLink: string;
  sLinkedInSigninLink: string;
  bDisplayPassword: boolean = false;
  bDisplayConfirmPassword: boolean = false;
  bDisplayErrorMsg: boolean;
  sErrorMessage: string;
  userEmail: string = '';
  constructor(private oFormBuilder: FormBuilder, private onboardingManager: OnboardingManagerService, private activatedRoute: ActivatedRoute, private oRouter: Router, private oDataService: cDataService, private oSessionService: cSessionService, public errorService: ErrorService) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // console.log("URL param",params);
      this.userEmail = params['singupemail'];
      if(this.userEmail && this.userEmail !=''){
        this.oSessionService.setTryNowEmail(this.userEmail);
      } else {
        this.oSessionService.setTryNowEmail(undefined);
        this.oSessionService.removeCookie("emailTryNow");
      }

      // console.log(" setting try now ", this.oSessionService.getTryNowEmail());
      // console.log(params['singupemail']) ;
        this.CreateAccountComponent_InitializeCreateAccountForm()
  });
  }

  ngOnInit(): void
  {
    this.CreateAccountComponent_InitializeCreateAccountForm()
    this.CreateAccountComponent_CreateSigninWithSocialMediaChannelsLink()
  }
  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value
    return pass === confirmPass ? null : { notSame: true }
  }
  CreateAccountComponent_InitializeCreateAccountForm() {
    const sEmailRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,14}$)/;

    const passRejex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\-#\$%\^&\*]).{8,}$/;
    this.fCreateAccountform = this.oFormBuilder.group(
      {
        name: ['', [Validators.required]],
        email: [this.userEmail, [Validators.required, Validators.email, Validators.pattern(sEmailRejex)]],
        password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(passRejex)]],
        confirmPassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern(passRejex)]],
      }, { validators: this.checkPasswords }
      // {validator: MustMatch('password', 'confirmPassword')}
    );
  }




  singup() {
    if (this.fCreateAccountform.valid) {
      let userProfile = new profile();
      userProfile.email = this.fCreateAccountform.get('email').value;
      userProfile.name = this.fCreateAccountform.get('name').value;
      userProfile.password = this.fCreateAccountform.get('password').value;
      userProfile.is_subscribed_to_email = 0;
      this.onboardingManager.signUp(userProfile)
        .then(_ => {
          // this.oRouter.navigateByUrl('/createAccountCompany');
          this.oRouter.navigateByUrl('/verify-email');
        })
        .catch(err => {
          if (err.errorMessage == 'user already exists') {
            this.errorService.handleError("You're already registered! Sign in with your email and password to access your account. Forgot your password? Click below to reset it.", 'Already Registered', true);
        }
        else {
            this.errorService.handleError(err.errorMessage, 'Already Registered', true);
          }
          // this.sErrorMessage = err.errorMessage;
          // this.bDisplayErrorMsg = true;
          // setTimeout(() => {
          //   this.bDisplayErrorMsg = false;
          // }, 6000)
        });
    } else {
      this.fCreateAccountform.markAllAsTouched();
    }
  }





  CreateAccountComponent_CreateSigninWithSocialMediaChannelsLink() {
    this.sGoogleSigninLink = this.oDataService.googleJoinLink + "?return_url=0";
    this.sFacebookSigninLink = this.oDataService.faceBookJoinLink + "?return_url=0";
    this.sLinkedInSigninLink = this.oDataService.linkedInJoinLink + "?return_url=0";
  }


}
