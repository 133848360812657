
<div id="main-content" [ngClass]="this.selectListView ? 'main-area' : ''">
  <div class="row filter-post" style="position: relative;" [ngClass]="{
    'media-lib-resize': uploadingMedia || selectmedia || multiselectmedia}">
    <div class="col-md-6 p-0 mid-library">
      <ul class="breadcrumb-tabs nav mx-1">
        <li *ngFor="let option of lBreadcrumbOptions; let i = index" class="nav-item" role="presentation"
          (click)="MediaLibrarySharedComponent_SelectMediaType(option.filter)">
          <a class="nav-link" [class.active]="option.filter == sVisualType">{{
            option.name
            }}</a>
        </li>

        <!-- <li class="nav-item" role="presentation">
                    <a class="nav-link" > Icons</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" > Text Snippets</a>
                </li>

                <li class="divader-left  nav-item">
                <a class="nav-link " href="javascript:void(0)" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">   Library Settings </a>
                </li> -->
      </ul>
    </div>
    <ul class="text-end d-block media-lib-header m-0 pe-5" style="position: absolute; bottom: -9px; right: 0" [ngClass]="this.selectListView ? 'col-lg-4' : 'col-lg-6'">
        <li class="layout-btn mt-3">
            <button (click)="gridView()" id="gridView" class="grid button-gl active">
                <img class="svg-icon" src="../../../../assets/images/svg/grid view.svg">
            </button>
            <button (click)="listView()" id="listView" class="list button-gl">
                <img class="svg-icon" src="../../../../assets/images/svg/list view.svg">
            </button>
        </li></ul>
  </div>

  <div class="row video-header mx-0" *ngIf="!userAccountMediaLib && !manageBrandMediaLib" [ngClass]="{
      'media-lib-resize': uploadingMedia || selectmedia || multiselectmedia
    }">
    <ul class="container p-0 mb-1 d-flex justify-content-between align-items-center">
      <li class="col-md-1 m-0" (click)="openslect()" *ngIf="bDisplaySelect">
        <div class="text-center">
          <img src="../../../assets/images/svg/search.svg">
        </div>
      </li>
      <li class="col-md-4 mb-2 pe-3" *ngIf="!bDisplaySelect">
        <div class="formItm search-icon searchDropdown">
          <input id="search-feild" type="search" [(ngModel)]="search" value="" placeholder="Search your video"
            required="" />
          <label for="search-feild" class="text-sm" style="cursor: default">Search media</label>
          <img src="../../../assets/images/svg/search.svg" (click)="searchMediaLibrary()">
          <!--   <ul class="pt-2 searchDropDownList d-none" id="searchDropDownList">
                    <li>
                        <ul class="searchImageList">
                            <h5>Images (121 Results)</h5>
                    <li>
                        <div class="d-flex pe-4">
                        <img src="/assets/images/videoTimeline/autoCreateModalImg.png">
                        <p>autoCreateModalImg.png</p> </div>
                    </li>
                    <li>
                        <div class="d-flex pe-4">
                        <img src="/assets/images/videoTimeline/autoCreateModalImg.png">
                        <p>autoCreateModalImg.png</p> </div>
                    </li>
                    <h6>View more</h6>
                        </ul>
                    </li>

                    <li>
                        <ul class="searchVideoList">
                            <h5>Video (11 Results)</h5>
                    <li>
                        <div class="d-flex pe-4">
                        <img src="/assets/images/videoTimeline/autoCreateModalImg.png">
                        <p>autoCreateModalImg.png</p> </div>
                    </li>
                    <li>
                        <div class="d-flex pe-4">
                        <img src="/assets/images/videoTimeline/autoCreateModalImg.png">
                        <p>autoCreateModalImg.png</p> </div>
                    </li>
                    <h6>View more</h6>
                        </ul>
                    </li>



                </ul> -->
        </div>
      </li>

    </ul>
  </div>

  <div class="row video-header mx-0" *ngIf="userAccountMediaLib || manageBrandMediaLib"
    style="margin-left: -5px !important;">
    <ul class="container p-0 mb-1 d-flex justify-content-between align-items-center">
      <li class="col-md-4 mb-2 pe-3">
        <div class="formItm search-icon searchDropdown">
          <input id="search-feild" type="search" [(ngModel)]="search" value="" placeholder="Search your video"
            required="" />
          <label for="search-feild" class="text-sm" style="cursor: default">Search media</label>
          <img src="../../../assets/images/svg/search.svg" (click)="searchMediaLibrary()">
        </div>
      </li>
      <li class="col-md-4 mb-2 ps-2" id="selectpreff">
        <div class="formItm down_arrow me-1 mt-4  search-sort-panel">
          <div class="dropdown dropdownBtn">
            <button class="dropdown-toggle text-md" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
              <span class="dropdownBtnText"><strong>Sort by </strong>
                {{ (sortBy === 'name') ? 'Name' : 'Created'}}
              </span>
            </button>
            <ul class="dropdown-menu dropdown-menu-end dropdownMenu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a class="dropdown-item text-md" href="javascript:void(0)" (click)="sortBySearch('name')">Name
                </a>
              </li>
              <li>
                <a class="dropdown-item text-md" href="javascript:void(0)" (click)="sortBySearch('created')">Created
                </a>
              </li>
            </ul>

          </div>
          <div class="sort-arrows pos-right">
            <img src="../../../../../assets/images/DataSort-1.svg" height="25px" *ngIf="sortType == -1" (click)="sortTypeSearch(1)">
            <img src="../../../../../assets/images/DataSort-3.svg" height="25px"  *ngIf="sortType == 1" (click)="sortTypeSearch(-1)">
          </div>
        </div>
      </li>
      <li class="col-md-4"></li>


    </ul>
  </div>
  <!-- ----------------------------------------------------------- -->
  <!--Select Singal and Multiple Visual and show in sidebar start  -->
  <!-- ----------------------------------------------------------- -->
  <div class="loadTableData loadingDataGrid" *ngIf="this.accountSettingService.showGridDataLoader">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="row grid m-0 select-media" id="media-view" style="margin-left: -20px !important;" [ngClass]="this.selectListView ? 'media-lib-resize' : ''">

    <ng-container *ngIf="!bSelectMultipleVisuals">
      <li id="#selectmedia" *ngFor="let visual of this.mediaManager.visuals" (click)="selectVisual(visual)"
        class="media-content media-li" [ngClass]="this.listview ? 'lib-list-width' : ''" [ngStyle]="{
          height: !this.listview ? '130px' : '60px'}">
        <!-- audio -->
        <section *ngIf="visual.visual_type == 'audio'" class="min-w">
          <div class="video-outer audio_design media-content-inner min-w" [ngStyle]="{
              border:
                visual.visual_path ==
                this.mediaManager.selectedVisual?.visual_path
                  ? '2px solid #FF8369'
                  : '0px solid #FF8369',
              'border-radius':
                visual.visual_path ==
                this.mediaManager.selectedVisual?.visual_path
                  ? '6px'
                  : '0px'
            }" [ngClass]="this.listview ? 'lib-list-style' : ''">
            <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
              <div id="loader-inner" class="media-loader-inner"></div>
            </div>

            <div class="audio_wrapper">
              <a href="javascript:void(0)">
                <img src="../../../assets/images/svg/audio-mic.svg">
              </a>
              <figure class="mb-0">
                <img [src]="this.imageStaticThumb" alt="audio-line" />
              </figure>
            </div>
            <p style="cursor: pointer" [ngClass]="this.listview ? 'd-none' : ''">
              {{ returnFileName(visual?.fpath?.split('/').pop())}}
            </p>
            <!-- <p class="text-xs m-0" style="height: 5vh;">Open your<br>{{visual?.visual_path.split('/').pop()}} }}</p> -->
            <span class="audio-time text-gray-3 text-xxs" [ngClass]="this.listview ? 'd-none' : ''">{{ visual?.duration | secondsToTime }}</span>
          </div>
          <div style="width: 250px; margin-left: 20px;" [ngClass]="!this.listview ? 'd-none' : ''">
              <p class="m-0 mb-1 lib-list-view-title" style="cursor: pointer">
                  {{ returnFileName(visual?.fpath?.split('/').pop()) }}
              </p>
              <span class="audio-time text-gray-3 text-xxs media-list-view-duration">{{ visual?.duration | secondsToTime }}</span>
              <p class="m-0 created-text">Created on {{ visual?.created?.$date | date}}</p>
          </div>
          <div class="custom-radiobtn radio-button">
            <label>
              <!-- <input type="checkbox" class="check form-check-input" [checked]="visual.isSelected" (click)="selectVisual(visual)"> -->
            </label>
          </div>
        </section>
        <!-- image -->

        <section *ngIf="visual.visual_type == 'image'" class="min-w">
          <div class="video-outer media-content-inner min-w" [ngStyle]="{
              border:
                visual.visual_path ==
                this.mediaManager.selectedVisual?.visual_path
                  ? '2px solid #FF8369'
                  : '0px solid #FF8369',
              'border-radius':
                visual.visual_path ==
                this.mediaManager.selectedVisual?.visual_path
                  ? '6px'
                  : '0px'
            }" [ngClass]="this.listview ? 'lib-list-style' : ''">
            <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
              <div id="loader-inner" class="media-loader-inner"></div>
            </div>

            <div class="video_wrapper video_wrapper_full js-videoWrapper pt-0 min-w" [ngStyle]="{
              height: !this.listview ? '100px' : ''}">
              <figure class="video-baner">
                <img [src]="visual.thumbnail_signed_url" alt="image" style="object-fit: cover" />
              </figure>
              <!-- <p class="text-md m-0" style="height: 5vh;">{{visual?.visual_path.split('/').pop()}}</p> -->
            </div>
            <p style="cursor: pointer" [ngClass]="this.listview ? 'd-none' : ''">
              {{ returnFileName(visual?.fpath?.split('/').pop())}}
            </p>
          </div>
          <div style="width: 250px; margin-left: 20px;" [ngClass]="!this.listview ? 'd-none' : ''">
              <p class="m-0 mb-1 lib-list-view-title" style="cursor: pointer">
                  {{ returnFileName(visual?.fpath?.split('/').pop()) }}
              </p>
              <p class="m-0 created-text">Created on {{ visual?.created?.$date | date}}</p>
          </div>
          <div class="custom-radiobtn radio-button">
            <label>
              <!-- <input type="checkbox" class="check form-check-input" [checked]="visual.isSelected" (click)="selectVisual(visual)"> -->
            </label>
          </div>
        </section>

        <!-- video -->
        <section *ngIf="visual.visual_type == 'clip'" class="min-w">
          <div class="video-outer media-content-inner min-w" [ngStyle]="{
              border:
                visual.visual_path ==
                this.mediaManager.selectedVisual?.visual_path
                  ? '2px solid #FF8369'
                  : '0px solid #FF8369',
              'border-radius':
                visual.visual_path ==
                this.mediaManager.selectedVisual?.visual_path
                  ? '6px'
                  : '0px'
            }" [ngClass]="this.listview ? 'lib-list-style' : ''">
            <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
              <div id="loader-inner" class="media-loader-inner"></div>
            </div>

            <div class="video_wrapper video_wrapper_full js-videoWrapper pt-0 min-w" [ngStyle]="{
              height: !this.listview ? '100px' : ''}">
              <video controls controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" [muted]="true"
                (click)="selectVisual(visual)" [src]="visual.signed_url" [id]="visual.visual_path"></video>

              <!-- <p class="text-md m-0" style="height: 5vh;">{{visual?.visual_path.split('/').pop()}}</p> -->
              <figure class="video-baner">
                <img [src]="visual.thumbnail_signed_url" alt="video1" style="object-fit: cover" />
                <span class="video-duration" [ngClass]="this.listview ? 'd-none' : ''" >{{ visual.duration | secondsToTime}}</span>
              </figure>
              <button class="videoPoster js-videoPoster play-btn" (click)="selectVisual(visual)"></button>
            </div>

            <p style="cursor: pointer" [ngClass]="this.listview ? 'd-none' : ''" >
              {{ returnFileName(visual?.fpath?.split('/').pop()) }}
            </p>
          </div>
          <div style="width: 250px; margin-left: 20px;" [ngClass]="!this.listview ? 'd-none' : ''">
              <p class="m-0 mb-1 lib-list-view-title" style="cursor: pointer">
                  {{ returnFileName(visual?.fpath?.split('/').pop()) }}
              </p>
              <span class="audio-time text-gray-3 text-xxs media-list-view-duration">{{ visual?.duration | secondsToTime }}</span>
              <p class="m-0 created-text">Created on {{ visual?.created?.$date | date}}</p>
          </div>
          <div class="custom-radiobtn radio-button">
            <label>
              <!-- <input type="checkbox" class="check form-check-input" [checked]="visual.isSelected" (click)="selectVisual(visual)"> -->
            </label>
          </div>
        </section>
        <!-- </nz-spin> -->
      </li>
    </ng-container>

    <ng-container *ngIf="bSelectMultipleVisuals">
      <li id="#multiselectmedia" *ngFor="let visual of this.mediaManager.visuals" class="media-content media-li">
        <!-- <nz-spin [nzSpinning]="visual.loadingThumbnail"> -->

        <section *ngIf="visual.visual_type == 'image'" class="min-w">
          <div class="video-outer media-content-inner min-w" [ngStyle]="{
              border: isActiveMultiSelection(visual)
                ? '2px solid #FF8369'
                : '0px solid #FF8369',
              'border-radius':
                visual.visual_path ==
                this.mediaManager.selectedVisual?.visual_path
                  ? '6px'
                  : '0px'
            }">
            <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
              <div id="loader-inner" class="media-loader-inner"></div>
            </div>

            <div class="video_wrapper video_wrapper_full js-videoWrapper pt-0 min-w" style="height: 100px">
              <figure class="video-baner">
                <img [src]="visual.url" alt="image" style="object-fit: cover" />
              </figure>
              <!-- <p class="text-md m-0" style="height: 5vh;">{{visual?.visual_path.split('/').pop()}}</p> -->
            </div>
            <p style="cursor: pointer">
              {{ returnFileName(visual?.fpath?.split('/').pop())}}
            </p>
          </div>
          <div>
            <label>
              <input type="checkbox" class="check form-check-input multiselectcheck" [checked]="visual.isSelected"
                (change)="selectVisual(visual)">
            </label>
          </div>
        </section>

        <!-- audio -->
        <section *ngIf="visual.visual_type == 'audio'" class="min-w">
          <div class="video-outer media-content-inner audio_design min-w" [ngStyle]="{
              border: isActiveMultiSelection(visual)
                ? '2px solid #FF8369'
                : '0px solid #FF8369',
              'border-radius':
                visual.visual_path ==
                this.mediaManager.selectedVisual?.visual_path
                  ? '6px'
                  : '0px'
            }">
            <div class="audio_wrapper">
              <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
                <div id="loader-inner" class="media-loader-inner"></div>
              </div>

              <a href="#">
                <img src="../../../assets/images/svg/audio-mic.svg">
              </a>

              <figure>
                <img [src]="this.imageStaticThumb" alt="audio-line" />
              </figure>
            </div>
            <!-- <p class="text-md m-0" style="height: 5vh;">{{visual?.visual_path.split('/').pop()}}</p> -->
            <p style="cursor: pointer">
              {{ returnFileName(visual?.fpath?.split('/').pop())}}
            </p>
            <span class="audio-time text-gray-3 text-xxs">{{ visual?.duration | secondsToTime }}</span>
          </div>
          <div>
            <label>
              <input type="checkbox" class="check form-check-input multiselectcheck" [checked]="visual.isSelected"
                (change)="selectVisual(visual)">
            </label>
          </div>
        </section>
        <!-- video -->
        <section *ngIf="visual.visual_type == 'clip'" class="min-w">
          <div class="video-outer media-content-inner min-w" [ngStyle]="{
              border: isActiveMultiSelection(visual)
                ? '2px solid #FF8369'
                : '0px solid #FF8369',
              'border-radius':
                visual.visual_path ==
                this.mediaManager.selectedVisual?.visual_path
                  ? '6px'
                  : '0px'
            }">
            <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
              <div id="loader-inner" class="media-loader-inner"></div>
            </div>

            <div class="video_wrapper video_wrapper_full js-videoWrapper pt-0 min-w" style="height: 100px">
              <!-- <video controls class="videoIframe js-videoIframe" [muted]="true" [src]="visual.url" [id]="visual.id"></video> -->
              <figure class="video-baner">
                <img [src]="visual.thumbnail" alt="video1" />
                <!-- <p class="text-md m-0" style="height: 5vh;">{{visual?.visual_path.split('/').pop()}}</p> -->
                <span class="video-duration">{{ visual?.duration | secondsToTime}}</span>
              </figure>
              <button class="videoPoster js-videoPoster play-btn" (click)="
                  MediaLibrarySharedComponent_PlayVideoPosterButton(visual)
                "></button>
            </div>
            <p style="cursor: pointer">
              {{ returnFileName(visual?.fpath?.split('/').pop()) }}
            </p>
          </div>
          <div>
            <label>
              <input type="checkbox" class="check form-check-input multiselectcheck" [checked]="visual.isSelected"
                (click)="selectVisual(visual)">
            </label>
          </div>
        </section>
        <!-- </nz-spin> -->
      </li>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-sm-12 text-end" *ngIf="this.mediaManager?.visualsTotalCount > 0 ">
      <mat-paginator style="margin-top: 15px;" pageIndex="currentPage" [length]="this.mediaManager?.visualsTotalCount"
        [pageSize]="nPageSize" [pageSizeOptions]="[15, 20, 25, 50]" (page)="loadPageEvent($event)"
        showFirstLastButtons></mat-paginator>
    </div>
  </div>


  <!-- Access Media Library sidebar Start-->
  <nav *ngIf="showMediaLibSelector && showMediaLibSelector == true"
    class="rightSideBar right-0 sidebar-block pb-4 media-library-access-drawer active" style="z-index: 1;">
    <div class="px-4 py-2 mb-0">
      <div class="video-header row mx-0 my-2">
        <p class="text-lg text-gray-2 col-lg-6 mb-0 px-0 pt-2">
          Media Library Access
        </p>
        <ul class="text-end d-block col-lg-6 mb-1">
          <li id="selectpreff" class="m-0 p-0">
            <div class="formItm down_arrow me-0">
              <div class="dropdown dropdownBtn">
                <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="ms-2 dropdownBtnText">All Libraries</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                  <li>
                    <a class="dropdown-item text-md" (click)="toggleMediaLibs('all')">Select All</a>
                  </li>
                  <li>
                    <a class="dropdown-item text-md" (click)="toggleMediaLibs('clear')">Deselect All</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>


      </div>

      <div class="library-access p-0 m-0 d-flex flex-wrap">
        <div class="d-flex w-160 pt-3 ps-2" *ngFor="let item of seletedMediaLibraryList">
          <label class="btnSwitch">
            <input type="checkbox" *ngIf="item.isSelected != undefined && item.isSelected == true" checked
              (click)="selectLibraryForSearch(item)" />
            <input type="checkbox" *ngIf="item.isSelected == undefined || item.isSelected == false"
              (click)="selectLibraryForSearch(item)" />
            <span class="btnSwitchCheck"></span>
          </label>
          <p class="text-md text-gray-2 pt-1 ps-2 text-break">{{ item.name }}</p>
        </div>

      </div>

      <p class="ps-1 pt-2" *ngIf="seletedMediaLibraryList?.length > 20">
        <a href="javascript:void(0)" class="text-sm text-primary-4 my-2 mx-3 d-inline-block ms-0">View All</a>
      </p>
    </div>
  </nav>

  <!-- Access Media Library sidebar end-->

  <div style="position: absolute; right: 6%; bottom: 2%" (click)="openLoading()">
    <button *ngIf="this.mediaManager.uploadMediaList.length > 0"
      class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 mr-2">
      Show Uploading
    </button>
  </div>
  <!-- Select Media  sidebar Modal Start-->
  <nav id="selectmedia" class="rightSideBar right-0 sidebar-block fancy-scroll" style="z-index: 10000; position:fixed;height: 83vh;
  margin-top: 15vh;
  margin-right: 2.1vw; z-index: 1;">
    <div class="sidebar-header">
      <h6 class="text-gray-2 weight-700">
        {{ this.mediaManager.selectedVisual?.name | slice: 0:22 }}
      </h6>
      <div  class="link link-hover" [ngClass]="{'copyPathclicked' : copyPathclicked}" (click)="copyPath(this.mediaManager.selectedVisual.fpath)" style="color: #333; font-size: 12px; background-color: #eee; width: 93px; padding: 5px; border-radius: 17px">
          <img
              _ngcontent-uha-c285=""
              src="../assets/images/icons/content_copy.svg"
              title="copy"
              class="link link-animation"
              style="height: 16px; width: 20px"

          />
          Copy Path
      </div>
      <!-- <a href="javascript:void(0)" class="del-media" data-bs-toggle="modal" data-bs-target="#delMedia-popup">
        <img src="../../../assets/images/svg/delete.svg">
      </a> -->
      <div class="closeRightSideBar pt-2" style="top: 18px; right: 15px" (click)="close()">
        <a (click)="
            MediaLibrarySharedComponent_StopAudio(
              this.mediaManager.selectedVisual?.url
            )
          ">
          <img src="../../../assets/images/svg/drawer-close.svg">
        </a>
      </div>
    </div>

    <div class="sidebar-content">
      <div class="seleted-media">
        <!-- image -->
        <section *ngIf="this.mediaManager.selectedVisual?.visual_type == 'image'" style="padding-bottom: 20px">
          <div class="video-outer">
            <div class="video_wrapper js-videoWrapper">
              <figure class="video-baner">
                <img [src]="this.mediaManager.selectedVisual.thumbnail_signed_url" alt="video1"
                  style="height: 18vh; object-fit: cover" />
              </figure>
            </div>
          </div>
        </section>
        <!-- audio -->
        <section *ngIf="this.mediaManager.selectedVisual?.visual_type == 'audio'" style="padding-bottom: 35px">
          <div class="video-outer audio_design">
            <div class="audio_wrapper">
              <a (click)="
                  MediaLibrarySharedComponent_PlayAudio(
                    this.mediaManager.selectedVisual.url
                  )
                ">
                <img src="../../../assets/images/svg/audio-mic.svg" style="width: auto;">
              </a>
              <figure>
                <img [src]="this.imageStaticThumb" alt="audio-line" />
              </figure>
            </div>
            <!-- <p class="text-md m-0" style="height: 5vh">
              {{ returnFileName(this.mediaManager?.selectedVisual?.name) }}
            </p> -->
            <span class="audio-time text-gray-3 text-xxs">{{ this.mediaManager.selectedVisual?.duration | secondsToTime}}</span>
          </div>
        </section>
        <!-- video -->
        <section *ngIf="this.mediaManager.selectedVisual?.visual_type == 'clip'">
          <div class="video-outer">
            <div style="padding-bottom: -1px" #playVideo [id]="this.mediaManager.selectedVisual.signed_url"
              class="video_wrapper js-videoWrapper" [ngClass]="
                this.mediaManager.selectedVisual?.isSelected
                  ? 'videoWrapperActive'
                  : ''
              ">
              <video #myVideo controls controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe"
                style="padding-bottom: 34px" [muted]="true" [src]="this.mediaManager.selectedVisual?.signed_url"
                [id]="this.mediaManager.selectedVisual?.visual_path"
                [poster]="this.mediaManager.selectedVisual?.thumbnail_signed_url"></video>
              <figure class="video-baner">
                <img [src]="this.mediaManager.selectedVisual.thumbnail_signed_url" alt="video1" />
                <span class="video-duration">{{ this.mediaManager.selectedVisual?.duration | secondsToTime }}</span>
              </figure>
              <button style="margin-top: -30px" class="videoPoster js-videoPoster play-btn" (click)="
                  MediaLibrarySharedComponent_PlayVideo(
                    this.mediaManager.selectedVisual
                  )
                "></button>
            </div>
          </div>
        </section>
      </div>
      <div class="formItm addclass mb-2" style="margin-top: -25px"
        *ngIf="this.mediaManager.selectedVisual?.visual_type != 'audio'">
        <div class="media-tags createTag_wrapp">
          <p class="text-gray-2 text-lg mb-2">Keywords</p>
          <span class="tag mb-2" *ngFor="
              let tag of this.mediaManager.selectedVisual?.key_terms;
              index as i
            ">{{ tag }}
            <img src="../../../assets/images/svg/close.svg" class="remove-tag" (click)="removeTag(tag)">
          </span>
          <div class="d-block">
            <div class="tagfield createTag_wrappAdd" [ngClass]="bDisplayAddInputField ? 'd-inline-block' : 'd-none'">
              <input class="add-Tag" maxlength="32" #AddInputTagElement (keydown.enter)="$event.preventDefault()"
                onkeypress="return event.charCode != 32" (focusout)="CreateVideoLooknFeelComponent_FocusoutFromInput()"
                (keydown)="
                  CreateVideoLooknFeelComponent_EnterKeyTermsTag($event.keyCode)
                " [(ngModel)]="inputValue" [ngModelOptions]="{ standalone: true }" type="text"
                (change)="CreateVideoLooknFeelComponent_GetInputValue($event)" /><input class="tag-btn" type="button"
                value="add" [disabled]="!inputValue" (click)="CreateVideoLooknFeelComponent_AddTag()"
                toggle-class="#createTag_wrapp" />
            </div>
            <a *ngIf="!bDisplayAddInputField" href="javascript:void(0)"
              (click)="CreateVideoLooknFeelComponent_DisplayAddTagInput()"
              class="text-sm text-primary-4 ms-2 createTag_wrappAddBtn" toggle-class="#createTag_wrapp">
              <img src="../../../assets/images/svg/add.svg">
              Add a Keyword
            </a>
          </div>
        </div>
      </div>

      <!--    <ul class="used-in my-4 px-0 py-4 border-bottom border-top">
            <p class="text-gray-2 text-lg fw-bold mb-3">Used In</p>
            <li class="d-block text-gray-2 text-xs" *ngFor="let video of this.mediaManager.selectedVisual?.videos_used_in">
                <figure>
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path  d="M0.666626 7.08372C0.666626 7.60276 1.04408 7.81531 1.50557 7.55635L6.98715 4.47245C7.44867 4.21293 7.44867 3.78785 6.98715 3.52833L1.50557 0.444132C1.04405 0.184609 0.666626 0.396868 0.666626 0.916767V7.08372Z" fill="#2B2F32" />
                    </svg>
                </figure>
                {{video}}
            </li>
        </ul> -->

      <div class="m-0 pb-2">
        <p class="text-gray-2 text-lg fw-bold mb-3">Media Info</p>
        <p class="d-flex cruunt-plan">
          <span class="text-gray-2 text-xs">Added on</span>
          <span class="text-gray-2 text-xs fw-bold text-end pe-2">{{
            this.mediaManager.selectedVisual?.created?.$date | slice : 0 : 10
            }}</span>
        </p>
        <p class="d-flex cruunt-plan">
          <span class="text-gray-2 text-xs">File Name</span>
          <span class="text-gray-2 text-xs fw-bold text-end pe-2  text-break">{{
            this.mediaManager.selectedVisual?.visual_path?.split('/').pop()
            }}</span>
        </p>
        <p class="d-flex cruunt-plan">
          <span class="text-gray-2 text-xs">File Size</span>
          <span class="text-gray-2 text-xs fw-bold text-end pe-2   text-break">{{
            BusinessProfileSidepanelsComponent_ReturnFileSize(
            this.mediaManager.selectedVisual?.size
            )
            }}</span>
        </p>
        <p class="d-flex cruunt-plan">
          <span class="text-gray-2 text-xs">Fpath</span>
          <span class="text-gray-2 text-xs fw-bold text-end pe-2   text-break">{{
            this.mediaManager.selectedVisual?.fpath
            }}</span>
        </p>
        <p class="d-flex cruunt-plan">
          <span class="text-gray-2 text-xs">Media Library</span>
          <span *ngIf=" !brandMediaLibraryName || brandMediaLibraryName ==''"
            class="text-gray-2 text-xs fw-bold text-end pe-2   text-break" style="word-wrap: break-word">
            {{ this.mediaManager.selectedVisual?.media_lib?.$oid }}</span>
          <span *ngIf="brandMediaLibraryName && brandMediaLibraryName !=''"
            class="text-gray-2 text-xs fw-bold text-end pe-2   text-break" style="word-wrap: break-word">
            {{ brandMediaLibraryName}}</span>
        </p>
      </div>
    </div>
  </nav>
  <!-- Select Media sidebar Modal end-->

  <!-- Multi Select media  sidebar Modal Start-->
  <!-- <nav id="multiselectmedia" class="rightSideBar right-0 sidebar-block" style="z-index: 10000">
    <div class="sidebar-header">
      <h6 class="text-gray-2 weight-700">
        {{ this.mediaManager.selectedVisuals?.length }} Items Selected
      </h6>
      <a href="#" class="del-media" data-bs-toggle="modal" data-bs-target="#delMedia-popup">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
            fill="#7A7A7A" />
        </svg>
      </a>
      <div class="closeRightSideBar pt-2" style="top: 18px; right: 15px" (click)="close()">
        <a (click)="MediaLibrarySharedComponent_CloseSidebar()">
          <img src="../../../assets/images/svg/drawer-close.svg">
        </a>
      </div>
    </div>
    <div class="sidebar-content">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of this.mediaManager.selectedVisuals; let i = index"
          class="slide slick-slider" style="margin-right: 5px" (click)="MediaLibrarySharedComponent_SelectedImage(i)">
          <a class="close-remove close-btn" (click)="selectVisual(slide)">
          </a>
          <img src="{{
              slide.visual_type != 'audio'
                ? slide.thumbnail || slide.url
                : this.imageStaticThumb
            }}" alt="" width="100%" />
        </div>
      </ngx-slick-carousel>
      <div class="formItm mb-2" style="margin-top: -25px">
        <div *ngIf="this.mediaManager.selectedVisuals?.length" class="media-tags createTag_wrapp pt-2 mt-1">
          <p class="text-gray-2 text-lg mb-2 ps-1 pt-4">Keywords</p>
          <span class="tag mb-2" *ngFor="let tag of this.commonTags; let i = index">{{ tag }}
            <img src="../../../assets/images/svg/close.svg" class="remove-tag" (click)="CreateVideoLooknFeelComponent_RemoveTag(i, tag)">
          </span>
          <div class="d-block mt-2">
            <div class="tagfield createTag_wrappAdd" [ngClass]="bDisplayAddInputField ? 'd-inline-block' : 'd-none'">
              <input class="add-Tag" maxlength="32" #AddInputElement (keydown.enter)="$event.preventDefault()"
                onkeypress="return event.charCode != 32" (focusout)="CreateVideoLooknFeelComponent_FocusoutFromInput()"
                (keydown)="
                  CreateVideoLooknFeelComponent_EnterKeyTermsTag($event.keyCode)
                " [(ngModel)]="inputValue" [ngModelOptions]="{ standalone: true }" type="text"
                (change)="CreateVideoLooknFeelComponent_GetInputValue($event)" /><input class="tag-btn" type="button"
                value="add" [disabled]="!inputValue" (click)="CreateVideoLooknFeelComponent_AddTag()"
                toggle-class="#createTag_wrapp" />
            </div>
            <a *ngIf="!bDisplayAddInputField" href="javascript:void(0)" (click)="
                CreateVideoLooknFeelComponent_DisplayAddTagMultiSelectedInput()
              " class="text-sm text-primary-4 ms-2 createTag_wrappAddBtn" toggle-class="#createTag_wrapp">
              <img src="../../../assets/images/svg/add.svg">
              Add a Keyword
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav> -->
  <!-- Multi Select media sidebar Modal end-->

  <!-- uploading sidebar Modal Start-->
  <!-- <nav id="uploadingMedia" class="rightSideBar right-0 sidebar-block uploading-sidebar" style="z-index: 10000">
    <div class="sidebar-header">
      <h6 class="text-gray-2 weight-700">
        Uploading {{ this.mediaManager.uploadMediaList.length }} items
      </h6>

      <div class="closeRightSideBar" style="top: 10px" (click)="close()">
        <a (click)="MediaLibrarySharedComponent_CloseSidebar()">
          <img src="../../../assets/images/svg/drawer-close.svg">
        </a>
      </div>
    </div>
    <div class="sidebar-content" style="height: 630px">
      <ul class="pt-2 uploadingList">
        <li *ngFor="let media of this.mediaManager.uploadMediaList" [ngStyle]="{
            opacity: media.status == 'uploading' ? '30%' : '100%',
            background:
              media.status == 'uploaded'
                ? 'transparent'
                : media.status == 'error'
                ? 'rgba(255, 0, 0, 0.1)'
                : 'transparent'
          }">
          <div class="d-flex justify-content-between">
            <p>
              <img [src]="this.imageStaticThumb" *ngIf="media.file.type.includes('audio')" />
              <img [src]="media.uri" *ngIf="media.file.type.includes('image')" />

              <video style="
                  top: 11px;
                  position: relative;
                  width: 45px;
                  height: 35px;
                  border-radius: 12px;
                  pointer-events: none;
                " *ngIf="media.file.type.includes('video')">
                <source [src]="media.uri" type="video/mp4" />
              </video>
              <span class="ps-2">{{ media.name | slice: -15 }}</span>
            </p>
            <p>
              <span class="pe-2">{{
                BusinessProfileSidepanelsComponent_ReturnFileSize(
                media.file.size
                )
                }}</span>
              <span class="pe-0" *ngIf="media.status == 'uploading'">
                <img class="image-rotate" src="/assets/images/loading-process-svgrepo-com.svg"
                  style="width: 15px; height: 15px" />
              </span>
              <span class="pe-0" *ngIf="media.status == 'uploaded'">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 48 48"
                  style="fill: #000000">
                  <linearGradient id="I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1" x1="9.858" x2="38.142" y1="9.858"
                    y2="38.142" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#00C599"></stop>
                    <stop offset="1" stop-color="#00C599"></stop>
                  </linearGradient>
                  <path fill="url(#I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1)"
                    d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                  <path
                    d="M32.172,16.172L22,26.344l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l8,8c0.781,0.781,2.047,0.781,2.828,0l13-13c0.781-0.781,0.781-2.047,0-2.828L35,16.172	C34.219,15.391,32.953,15.391,32.172,16.172z"
                    opacity=".05"></path>
                  <path
                    d="M20.939,33.061l-8-8c-0.586-0.586-0.586-1.536,0-2.121l1.414-1.414c0.586-0.586,1.536-0.586,2.121,0	L22,27.051l10.525-10.525c0.586-0.586,1.536-0.586,2.121,0l1.414,1.414c0.586,0.586,0.586,1.536,0,2.121l-13,13	C22.475,33.646,21.525,33.646,20.939,33.061z"
                    opacity=".07"></path>
                  <path fill="#fff"
                    d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0	L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414l-13,13	C22.317,33.098,21.683,33.098,21.293,32.707z">
                  </path>
                </svg>
              </span>
              <span class="pe-0" *ngIf="media.status == 'error'" (click)="deleteSelectedUploadingMedia(media)">
                <img src="/assets/images/cross.svg" style="width: 15px; height: 15px" [title]="media.error" /><br />
              </span>
            </p>
          </div>
          <h6 *ngIf="media.status == 'error'" style="color: red; font-size: 10px; line-height: 1">
            {{ media.error }}
          </h6>
        </li>
      </ul>
    </div>
  </nav> -->
  <!-- uploading sidebar Modal end-->

  <!-- ----------------------------------------------------------- -->
  <!--Select Singal and Multiple Visual and show in sidebar End  -->
  <!-- ----------------------------------------------------------- -->

  <!-- Modal pop-up Start-->
  <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    style="z-index: 10000">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Upload a Video or Audio File
          </h5>
          <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group files">
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                aria-labelledby="pills-device-tab">
                <div class="file-drop-area">
                  <figure>
                    <svg width="23" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                          fill="#7A7A7A" />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="16.0013" height="15.9809" fill="white" transform="translate(0 0.00958252)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </figure>
                  <span class="choose-file-button">Upload a File (Device)</span>
                  <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br />
                    drag & drop your file here</span>
                  <span class="file-message">{{ nFilesToUploadCount }} file selected</span>
                  <input #uploadFileField class="file-input" type="file" multiple
                    accept="image/jpg, image/png, image/jpeg, audio/mp3, video/mp4, video/avi"
                    (change)="MediaLibrarySharedComponent_UploadFiles($event)" />
                </div>
              </div>
              <ul class="nav mb-3" id="pills-tab" role="tablist">
                <li class="col nav-item" role="presentation">
                  <button (click)="uploadFileField.click()" class="nav-link" id="pills-device-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-device" type="button" role="tab" aria-controls="pills-device"
                    aria-selected="true">
                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon" />
                    <span>My Device</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal pop-up End-->

  <!-- Delete pop-up Start-->
  <div class="modal fade" id="delMedia-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <h5>Are you sure you want to delete the selected media files?</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4">
            The files will be permanently deleted. You can't undo this action.
          </p>
          <a href="javascript:void(0)" id="sidebarCollapse"
            class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal">Cancel</a>
          <a class="mt-3 text-gray-3 text-sm" href="javascript:void(0)" data-bs-dismiss="modal"
            (click)="MediaLibrarySharedComponent_DeleteVisual()">Delete</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Delete pop-up End-->