<div style="overflow-y: auto;
overflow-x: hidden; width: 100%;
height: calc(100vh - 200px);" class="fancy-scroll">
<div id="main-content">
<div class="row filter-post" style="position: relative;" [ngClass]="{
        'media-lib-resize': uploadingMedia || selectmedia || multiselectmedia}">
        <div class="col-md-6 p-0 mid-library">
            <ul class="breadcrumb-tabs nav mx-2">
                <li
                    *ngFor="let option of lBreadcrumbOptions; let i = index"
                    class="nav-item"
                    role="presentation"
                    (click)="MediaLibrarySharedComponent_SelectMediaType(option.filter)"
                >
                    <a class="nav-link" [class.active]="option.filter == sVisualType">{{ option.name }}</a>
                </li>

                <!-- <li class="nav-item" role="presentation">
                    <a class="nav-link" > Icons</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" > Text Snippets</a>
                </li>

                <li class="divader-left  nav-item">
                <a class="nav-link " href="javascript:void(0)" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">   Library Settings </a>
                </li> -->
            </ul>
        </div>
        <ul class="d-block media-lib-header m-0 col-lg-6" style="position: absolute; top: -10px; right: 0;">
            <li class="layout-btn mt-3" style="position: absolute;" [ngStyle]="{
                right: (uploadingMedia || selectmedia  || multiselectmedia) ? '15px'
                    : '42px'}">
                <button (click)="gridView()" id="gridView" class="grid button-gl active">
                    <img class="svg-icon" src="../../../../assets/images/svg/grid view.svg">
                </button>
                <button (click)="listView()" id="listView" class="list button-gl">
                    <img class="svg-icon" src="../../../../assets/images/svg/list view.svg">
                </button>
            </li></ul>
    </div>

    <div
        class="row video-header mx-0"
        [ngClass]="{
            'media-lib-resize': uploadingMedia || selectmedia || multiselectmedia
        }"
    >
        <ul class="container p-0 mb-1 d-flex justify-content-between align-items-center">
            <li class="col-md-1 m-0" (click)="openslect()" *ngIf="bDisplaySelect">
                <div class="text-center">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        style="margin-bottom: -2px"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                            fill="#A39FAA"
                        ></path>
                    </svg>
                </div>
            </li>
            <li class="col-md-4 mb-2 pe-3" *ngIf="!bDisplaySelect">
                <div class="formItm search-icon searchDropdown">
                    <input id="search-feild" type="search" [(ngModel)]="search" value="" placeholder="Search your video" required="" />
                    <label for="search-feild" class="text-sm" style="cursor: default">Search media</label>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        (click)="searchMediaLibrary()"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                            fill="#A39FAA"
                        ></path>
                    </svg>
                    <!--   <ul class="pt-2 searchDropDownList d-none" id="searchDropDownList">
                    <li>
                        <ul class="searchImageList">
                            <h5>Images (121 Results)</h5>
                    <li>
                        <div class="d-flex pe-4">
                        <img src="/assets/images/videoTimeline/autoCreateModalImg.png">
                        <p>autoCreateModalImg.png</p> </div>
                    </li>
                    <li>
                        <div class="d-flex pe-4">
                        <img src="/assets/images/videoTimeline/autoCreateModalImg.png">
                        <p>autoCreateModalImg.png</p> </div>
                    </li>
                    <h6>View more</h6>
                        </ul>
                    </li>

                    <li>
                        <ul class="searchVideoList">
                            <h5>Video (11 Results)</h5>
                    <li>
                        <div class="d-flex pe-4">
                        <img src="/assets/images/videoTimeline/autoCreateModalImg.png">
                        <p>autoCreateModalImg.png</p> </div>
                    </li>
                    <li>
                        <div class="d-flex pe-4">
                        <img src="/assets/images/videoTimeline/autoCreateModalImg.png">
                        <p>autoCreateModalImg.png</p> </div>
                    </li>
                    <h6>View more</h6>
                        </ul>
                    </li>



                </ul> -->
                </div>
            </li>
            <li class="col-md-2 mb-2 ps-2" id="selectpreff">
                <div class="formItm down_arrow me-1 mt-4 relative">
                    <div class="dropdown dropdownBtn">
                        <button class="dropdown-toggle text-md" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                            <span class="dropdownBtnText"
                                ><strong>Sort by </strong>
                                {{ sortBy === 'name' ? 'Name' : 'Created' }}
                            </span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end dropdownMenu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <a class="dropdown-item text-md" href="javascript:void(0)" (click)="sortBySearch('name')">Name </a>
                            </li>
                            <li>
                                <a class="dropdown-item text-md" href="javascript:void(0)" (click)="sortBySearch('created')">Created </a>
                            </li>
                        </ul>
                    </div>
                    <div class="sort-arrows pos-right link" *ngIf="this.sVisualType != 'all'">

            <img src="../../../../../assets/images/DataSort-1.svg" height="25px" *ngIf="sortType == -1" (click)="sortTypeSearch(1)">
            <img src="../../../../../assets/images/DataSort-3.svg" height="25px" *ngIf="sortType == 1" (click)="sortTypeSearch(-1)">
          </div>
                </div>
            </li>
            <!-- <li class="col-md-2 mb-2 ps-2 d-none" id="selectpreff">
        <div class="formItm down_arrow me-0 mt-4">
          <div class="dropdown dropdownBtn">
            <button class="dropdown-toggle text-md" type="button">
              <span class="dropdownBtnText"
                ><strong>Group by </strong>
                TimeLine
              </span>
            </button>
          </div>
        </div>
      </li> -->

            <li
                class="col-md-5 mb-2">
                <ul class="text-end d-block mt-3 pt-2" style="padding-right: 30px;" [ngClass]="!this.isOwner ? 'd-none' : ''">
                    <li class="pe-3 text-sm m-0">
                        <!-- <a href="javascript:void(0)" class="text-gray-3" data-sidebar="#multiselectmedia">Select Media</a> -->
                        <a href="javascript:void(0)" [ngClass]="bSelectMultipleVisuals ? 'text-primary-4' : 'text-gray-3'" (click)="openMultiSelectMedia()" >Select Media</a>
                    </li>
                    <li class="m-0">
                        <!-- <button
              class="btn weight-600 text-xs btn btn-primary btn-round-6 px-3 py-2"
              data-bs-toggle="modal"
              data-bs-target="#uploadModal"
            >
              Add Media
            </button> -->

                        <button class="btn weight-600 text-xs btn btn-primary btn-round-6 px-3 py-2" (click)="openFileUploadModal()">
                            Add Media
                        </button>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
    <!-- ----------------------------------------------------------- -->
    <!--Select Singal and Multiple Visual and show in sidebar start  -->
    <!-- ----------------------------------------------------------- -->

    <div class="row grid m-0 select-media relative" id="media-view">
        <div class="loadTableData loadingDataGrid" *ngIf="this.accountSettingService.showGridDataLoader">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <ng-container *ngIf="!bSelectMultipleVisuals">
            <li id="#selectmedia" *ngFor="let visual of this.mediaManager.visuals" (click)="selectVisual(visual)" class="media-content" [ngClass]="this.listview ? 'lib-list-width' : ''" style="margin-bottom: 15px!important;">
                <!-- audio -->
                <section *ngIf="visual.visual_type == 'audio'">
                    <div
                        class="video-outer audio_design media-content-inner"
                        [ngStyle]="{
                            border: visual.fpath == this.mediaManager.selectedVisual?.fpath ? '2px solid #FF8369' : '0px solid #FF8369'
                        }" [ngClass]="this.listview ? 'lib-list-style' : ''">
                        <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
                            <div id="loader-inner" class="media-loader-inner"></div>
                        </div>

                        <div class="audio_wrapper">
                            <a href="javascript:void(0)" class="muteBtn btn-hover-w voice-btn mb-2"
                            (mouseover)="MediaLibrarySharedComponent_PlayAudio(visual?.signed_url)"
                            (mouseout)="MediaLibrarySharedComponent_StopAudio(visual?.signed_url)" >

                            <img src="../assets/images/microphone-off.svg" class="off"
                            style="margin-top: 0px;margin-left: 4px;" width="12" height="11" />
                        <img src="../assets/images/microphone.svg" class="on"
                            style="margin-top: 0px;margin-left: 4px;" width="12" height="11" />
                            </a>
                            <figure class="mb-0">
                                <img [src]="this.imageStaticThumb" alt="audio-line" />
                            </figure>
                        </div>
                        <p style="cursor: pointer" [ngClass]="this.listview ? 'd-none' : ''">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                        <!-- <p class="text-xs m-0" style="height: 5vh;">Open your<br>{{visual?.fpath.split('/').pop()}} }}</p> -->
                        <span class="audio-time text-gray-3 text-xxs" [ngClass]="this.listview ? 'd-none' : ''">{{ visual?.duration | secondsToTime }}</span>
                    </div>
                    <div style="width: 250px; margin-left: 20px;" [ngClass]="!this.listview ? 'd-none' : ''">
                        <p class="m-0 mb-1 lib-list-view-title" style="cursor: pointer">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                        <span class="audio-time text-gray-3 text-xxs media-list-view-duration">{{ visual?.duration | secondsToTime }}</span>
                        <p class="m-0 created-text">Created on {{ visual?.created?.$date | date}}</p>
                    </div>
                    <div class="custom-radiobtn" [ngClass]="this.listview ? 'list-multi-selection' : ''">
                        <label>
                            <input
                                type="checkbox"
                                class="check form-check-input"
                                [checked]="visual.isSelected"
                                (click)="selectVisual(visual)"
                            />
                        </label>
                    </div>
                </section>
                <!-- image -->

                <section *ngIf="visual.visual_type == 'image'">
                    <div
                        class="video-outer media-content-inner"
                        [ngStyle]="{
                            border: visual.fpath == this.mediaManager.selectedVisual?.fpath ? '2px solid #FF8369' : '0px solid #FF8369'
                        }" [ngClass]="this.listview ? 'lib-list-style' : ''">
                        <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
                            <div id="loader-inner" class="media-loader-inner"></div>
                        </div>

                        <div class="video_wrapper video_wrapper_full js-videoWrapper">
                            <figure class="video-baner">
                                <img [src]="visual.thumbnail_signed_url" alt="image" style="object-fit: contain" />
                            </figure>
                            <!-- <p class="text-md m-0" style="height: 5vh;">{{visual?.fpath.split('/').pop()}}</p> -->
                        </div>
                        <p style="cursor: pointer" [ngClass]="this.listview ? 'd-none' : ''">
                            {{ returnFileName(visual?.fpath?.split('/').pop())}}
                        </p>
                    </div>
                    <div style="width: 250px; margin-left: 20px;" [ngClass]="!this.listview ? 'd-none' : ''">
                        <p class="m-0 mb-1 lib-list-view-title" style="cursor: pointer">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                        <p class="m-0 created-text">Created on {{ visual?.created?.$date | date}}</p>
                    </div>
                    <div class="custom-radiobtn" [ngClass]="this.listview ? 'list-multi-selection' : ''">
                        <label>
                            <input
                                type="checkbox"
                                class="check form-check-input"
                                [checked]="visual.isSelected"
                                (click)="selectVisual(visual)"
                            />
                        </label>
                    </div>
                </section>

                <!-- video -->
                <section *ngIf="visual.visual_type == 'clip' || visual.visual_type == 'video'">
                    <div
                        class="video-outer media-content-inner"
                        [ngStyle]="{
                            border: visual.fpath == this.mediaManager.selectedVisual?.fpath ? '2px solid #FF8369' : '0px solid #FF8369'
                        }" [ngClass]="this.listview ? 'lib-list-style' : ''">
                        <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
                            <div id="loader-inner" class="media-loader-inner"></div>
                        </div>

                        <div class="video_wrapper video_wrapper_full js-videoWrapper">
                            <video
                                controls
                                controlsList="nodownload noplaybackrate"
                                class="videoIframe js-videoIframe"
                                [muted]="true"
                                style="width: auto; max-width: max-content; margin: auto"
                                (click)="selectVisual(visual)"
                                [src]="visual.signed_url"
                                [id]="visual.fpath"
                            ></video>

                            <!-- <p class="text-md m-0" style="height: 5vh;">{{visual?.fpath.split('/').pop()}}</p> -->
                            <figure class="video-baner" style="width: auto; max-width: max-content; margin: auto">
                                <img [src]="visual.thumbnail_signed_url" alt="video1" />
                                <span class="video-duration"  [ngClass]="this.listview ? 'd-none' : ''" *ngIf="visual?.duration">{{ visual.duration | secondsToTime }}</span>
                            </figure>
                            <button class="videoPoster js-videoPoster" (click)="selectVisual(visual)"></button>
                        </div>

                        <p style="cursor: pointer" [ngClass]="this.listview ? 'd-none' : ''">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                    </div>
                    <div style="width: 250px; margin-left: 20px;" [ngClass]="!this.listview ? 'd-none' : ''">
                        <p class="m-0 mb-1 lib-list-view-title" style="cursor: pointer">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                        <span class="audio-time text-gray-3 text-xxs media-list-view-duration">{{ visual?.duration | secondsToTime }}</span>
                        <p class="m-0 created-text">Created on {{ visual?.created?.$date | date}}</p>
                    </div>
                    <div class="custom-radiobtn" [ngClass]="this.listview ? 'list-multi-selection' : ''">
                        <label>
                            <input
                                type="checkbox"
                                class="check form-check-input"
                                [checked]="visual.isSelected"
                                (click)="selectVisual(visual)"
                            />
                        </label>
                    </div>
                </section>
                <!-- </nz-spin> -->
            </li>
        </ng-container>

        <ng-container *ngIf="bSelectMultipleVisuals">
            <li id="#multiselectmedia" *ngFor="let visual of this.mediaManager.visuals" class="media-content" [ngClass]="this.listview ? 'lib-list-width' : ''">
                <!-- <nz-spin [nzSpinning]="visual.loadingThumbnail"> -->

                <section *ngIf="visual.visual_type == 'image'">
                    <div
                        class="video-outer media-content-inner"
                        [ngStyle]="{
                            border: isActiveMultiSelection(visual) ? '2px solid #FF8369' : '0px solid #FF8369'
                        }" [ngClass]="this.listview ? 'lib-list-style' : ''">
                        <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
                            <div id="loader-inner" class="media-loader-inner"></div>
                        </div>

                        <div class="video_wrapper video_wrapper_full js-videoWrapper">
                            <figure class="video-baner">
                                <img [src]="visual.thumbnail_signed_url" alt="image" style="object-fit: contain" />
                            </figure>
                            <!-- <p class="text-md m-0" style="height: 5vh;">{{visual?.fpath.split('/').pop()}}</p> -->
                        </div>
                        <p style="cursor: pointer" [ngClass]="this.listview ? 'd-none' : ''">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                    </div>
                    <div style="width: 250px; margin-left: 20px;" [ngClass]="!this.listview ? 'd-none' : ''">
                        <p class="m-0 mb-1 lib-list-view-title" style="cursor: pointer">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                        <p class="m-0 created-text">Created on {{ visual?.created?.$date | date}}</p>
                    </div>
                    <div>
                        <label>
                            <input [ngClass]="this.listview ? 'list-multi-selection' : ''"
                                type="checkbox"
                                class="check form-check-input multiselectcheck"
                                [checked]="visual.isSelected"
                                (change)="selectVisual(visual)"
                            />
                        </label>
                    </div>
                </section>

                <!-- audio -->
                <section *ngIf="visual.visual_type == 'audio'">
                    <div
                        class="video-outer media-content-inner audio_design"
                        [ngStyle]="{
                            border: isActiveMultiSelection(visual) ? '2px solid #FF8369' : '0px solid #FF8369'
                        }" [ngClass]="this.listview ? 'lib-list-style' : ''">
                        <div class="audio_wrapper">
                            <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
                                <div id="loader-inner" class="media-loader-inner"></div>
                            </div>
                            <a href="javascript:void(0)" class="muteBtn btn-hover-w voice-btn mb-2"
                            (mouseover)="MediaLibrarySharedComponent_PlayAudio(visual?.signed_url)"
                            (mouseout)="MediaLibrarySharedComponent_StopAudio(visual?.signed_url)" >

                            <img src="../assets/images/microphone-off.svg" class="off"
                            style="margin-top: 0px;margin-left: 4px;" width="12" height="11" />
                        <img src="../assets/images/microphone.svg" class="on"
                            style="margin-top: 0px;margin-left: 4px;" width="12" height="11" />
                            </a>

                            <figure>
                                <img [src]="this.imageStaticThumb" alt="audio-line" />
                            </figure>
                        </div>
                        <!-- <p class="text-md m-0" style="height: 5vh;">{{visual?.fpath.split('/').pop()}}</p> -->
                        <p class="m-0" style="cursor: pointer" [ngClass]="this.listview ? 'd-none' : ''">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                        <span class="audio-time text-gray-3 text-xxs" [ngClass]="this.listview ? 'd-none' : ''">{{ visual?.duration | secondsToTime }}</span>
                    </div>
                    <div style="width: 250px; margin-left: 20px;" [ngClass]="!this.listview ? 'd-none' : ''">
                        <p class="m-0 mb-1 lib-list-view-title" style="cursor: pointer">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                        <span class="audio-time text-gray-3 text-xxs media-list-view-duration">{{ visual?.duration | secondsToTime }}</span>
                        <p class="m-0 created-text">Created on {{ visual?.created?.$date | date}}</p>
                    </div>
                    <div>
                        <label>
                            <input [ngClass]="this.listview ? 'list-multi-selection' : ''"
                                type="checkbox"
                                class="check form-check-input multiselectcheck"
                                [checked]="visual.isSelected"
                                (change)="selectVisual(visual)"
                            />
                        </label>
                    </div>
                </section>
                <!-- video -->
                <section *ngIf="visual.visual_type == 'clip'">
                    <div
                        class="video-outer media-content-inner"
                        [ngStyle]="{
                            border: isActiveMultiSelection(visual) ? '2px solid #FF8369' : '0px solid #FF8369'
                        }" [ngClass]="this.listview ? 'lib-list-style' : ''">
                        <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
                            <div id="loader-inner" class="media-loader-inner"></div>
                        </div>

                        <div class="video_wrapper video_wrapper_full js-videoWrapper">
                            <!-- <video controls class="videoIframe js-videoIframe" [muted]="true" [src]="visual.url" [id]="visual.id"></video> -->
                            <figure class="video-baner" style="width: auto; max-width: max-content; margin: auto">
                                <img [src]="visual.thumbnail_signed_url" alt="video1" />
                                <!-- <p class="text-md m-0" style="height: 5vh;">{{visual?.fpath.split('/').pop()}}</p> -->
                                <span class="video-duration" [ngClass]="this.listview ? 'd-none' : ''">{{ visual?.duration | secondsToTime }}</span>
                            </figure>
                            <button
                                class="videoPoster js-videoPoster"
                                (click)="MediaLibrarySharedComponent_PlayVideoPosterButton(visual)"
                            ></button>
                        </div>
                        <p style="cursor: pointer" [ngClass]="this.listview ? 'd-none' : ''">
                            {{ returnFileName(visual?.fpath?.split('/').pop())}}
                        </p>
                    </div>
                    <div style="width: 250px; margin-left: 20px;" [ngClass]="!this.listview ? 'd-none' : ''">
                        <p class="m-0 mb-1 lib-list-view-title" style="cursor: pointer">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                        <span class="audio-time text-gray-3 text-xxs media-list-view-duration">{{ visual?.duration | secondsToTime }}</span>
                        <p class="m-0 created-text">Created on {{ visual?.created?.$date | date}}</p>
                    </div>
                    <div>
                        <label>
                            <input [ngClass]="this.listview ? 'list-multi-selection' : ''"
                                type="checkbox"
                                class="check form-check-input multiselectcheck"
                                [checked]="visual.isSelected"
                                (click)="selectVisual(visual)"
                            />
                        </label>
                    </div>
                </section>
                <!-- </nz-spin> -->
            </li>
        </ng-container>
    </div>
    <div class="row" *ngIf="this.mediaManager?.visualsTotalCount > 0">
        <div class="col-sm-12 text-end">
            <mat-paginator
                style="margin-top: 15px"
                pageIndex="nCurrentPage"
                [length]="this.mediaManager?.visualsTotalCount"
                [pageSize]="nPageSize"
                [pageSizeOptions]="[15, 20, 25, 50]"
                (page)="loadPageEvent($event)"
                showFirstLastButtons
            ></mat-paginator>
        </div>
    </div>

    <div style="position: absolute; right: 6%; bottom: 2%" (click)="openLoading()">
        <button
            *ngIf="this.mediaManager.uploadMediaList.length > 0"
            class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 mr-2"
        >
            Show Uploading
        </button>
    </div>
    <!-- Select Media  sidebar Modal Start-->
    <nav id="selectmedia" class="rightSideBar right-0 sidebar-block" style="width: 400px !important; z-index: 1;">
        <div class="sidebar-header">
            <h6 class="text-gray-2 weight-700">
                {{ this.mediaManager.selectedVisual?.name | slice : 0 : 22 }}
            </h6>
            <div  class="link link-hover" [ngClass]="{'copyPathclicked' : copyPathclicked}" (click)="copyPath(this.mediaManager.selectedVisual.fpath)" style="color: #333; font-size: 12px; background-color: #eee; width: 93px; padding: 5px; border-radius: 17px">
                <img
                    _ngcontent-uha-c285=""
                    src="../assets/images/icons/content_copy.svg"
                    title="copy"
                    class="link link-animation"
                    style="height: 16px; width: 20px"

                />
                Copy Path
            </div>
            <a
                [ngClass]="!this.isOwner ? 'd-none' : ''"
                href="javascript:void(0)"
                class="del-media"
                data-bs-toggle="modal"
                data-bs-target="#delMedia-popup"
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                        fill="#7A7A7A"
                    />
                </svg>
            </a>
            <div class="closeRightSideBar pt-2" style="top: 18px; right: 15px" (click)="close()">
                <a (click)="MediaLibrarySharedComponent_StopAudio(this.mediaManager.selectedVisual?.url)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_6)">
                            <path
                                d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                                fill="#F2F2F5"
                            />
                            <path
                                d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                                fill="#A7ABB6"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_6">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
        </div>

        <div class="sidebar-content">
            <div class="seleted-media">
                <!-- image -->
                <section *ngIf="this.mediaManager.selectedVisual?.visual_type == 'image'" style="padding-bottom: 20px">
                    <div class="video-outer">
                        <div class="video_wrapper js-videoWrapper">
                            <figure class="video-baner">
                                <img [src]="this.mediaManager.selectedVisual.thumbnail_signed_url" alt="video1" style="height: 18vh; object-fit: contain" />
                            </figure>
                        </div>
                    </div>
                </section>
                <!-- audio -->
                <section *ngIf="this.mediaManager.selectedVisual?.visual_type == 'audio'" style="padding-bottom: 35px">
                    <div class="video-outer audio_design">
                        <div class="audio_wrapper">
                            <a
                                (click)="
                                    MediaLibrarySharedComponent_PlayAudio(
                                        this.mediaManager.selectedVisual.url
                                            ? this.mediaManager.selectedVisual.url
                                            : this.mediaManager.selectedVisual.signed_url
                                    )
                                "
                            >
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M3.625 3C3.625 1.68789 4.68789 0.625 6 0.625C7.31211 0.625 8.375 1.68789 8.375 3V5.75C8.375 7.06211 7.31211 8.125 6 8.125C4.68789 8.125 3.625 7.06211 3.625 5.75V3ZM6 1.375C5.10211 1.375 4.375 2.10211 4.375 3V5.75C4.375 6.64789 5.10211 7.375 6 7.375C6.89789 7.375 7.625 6.64789 7.625 5.75V3C7.625 2.10211 6.89789 1.375 6 1.375ZM2.17505 4.44995C2.38216 4.44995 2.55005 4.61784 2.55005 4.82495V5.67495C2.55005 7.57784 4.09716 9.12495 6.00005 9.12495C7.90294 9.12495 9.45005 7.57784 9.45005 5.67495V4.82495C9.45005 4.61784 9.61794 4.44995 9.82505 4.44995C10.0322 4.44995 10.2 4.61784 10.2 4.82495V5.67495C10.2 7.86571 8.51691 9.66831 6.375 9.85839V11C6.375 11.2071 6.20711 11.375 6 11.375C5.79289 11.375 5.625 11.2071 5.625 11V9.85838C3.48314 9.66825 1.80005 7.86567 1.80005 5.67495V4.82495C1.80005 4.61784 1.96794 4.44995 2.17505 4.44995ZM5.43413 3.56714C5.80078 3.4327 6.19929 3.4327 6.56594 3.56714C6.76039 3.63843 6.97581 3.5386 7.04711 3.34415C7.11841 3.14971 7.01858 2.93428 6.82413 2.86298C6.29078 2.66742 5.70929 2.66742 5.17594 2.86298C4.98149 2.93428 4.88166 3.14971 4.95296 3.34415C5.02425 3.5386 5.23968 3.63843 5.43413 3.56714ZM6.30917 4.63748C6.10695 4.58406 5.89795 4.58406 5.69572 4.63748C5.49548 4.69037 5.29027 4.57092 5.23738 4.37068C5.18449 4.17045 5.30394 3.96524 5.50417 3.91235C5.83194 3.82577 6.17295 3.82577 6.50072 3.91235C6.70096 3.96524 6.8204 4.17045 6.76751 4.37068C6.71462 4.57092 6.50941 4.69037 6.30917 4.63748Z"
                                        fill="#FF8369"
                                    />
                                </svg>
                            </a>
                            <figure>
                                <img [src]="this.imageStaticThumb" alt="audio-line" />
                            </figure>
                        </div>
                        <!-- <p class="text-md m-0" style="height: 5vh">
                            {{ returnFileName(this.mediaManager.selectedVisual?.name) }}
                        </p>
                        <span class="audio-time text-gray-3 text-xxs">{{
                            this.mediaManager.selectedVisual?.duration | secondsToTime
                        }}</span> -->
                    </div>
                </section>
                <!-- video -->
                <section *ngIf="this.mediaManager.selectedVisual?.visual_type == 'clip'">
                    <div class="video-outer">
                        <div
                            style="padding-bottom: -1px; height: 210px"
                            #playVideo
                            [id]="this.mediaManager.selectedVisual.signed_url"
                            class="video_wrapper js-videoWrapper"
                            [ngClass]="this.mediaManager.selectedVisual?.isSelected ? 'videoWrapperActive' : ''"
                        >
                            <video
                                #myVideo
                                controls
                                controlsList="nodownload noplaybackrate"
                                *ngIf="this.mediaManager.selectedVisual.isSelected"
                                class="videoIframe js-videoIframe"
                                style="padding-bottom: 34px; max-height: 210px !important; margin: auto; width: auto"
                                [muted]="true"
                                [src]="this.mediaManager.selectedVisual?.signed_url"
                                [id]="this.mediaManager.selectedVisual?.fpath"
                                [poster]="this.mediaManager.selectedVisual?.thumbnail_signed_url"
                            ></video>
                            <figure class="video-baner" style="text-align: center" *ngIf="!this.mediaManager.selectedVisual.isSelected">
                                <img
                                    [src]="this.mediaManager.selectedVisual.thumbnail_signed_url"
                                    alt="video1"
                                    style="max-height: 210px !important; margin: auto; display: block; width: auto"
                                />
                                <span class="video-duration">{{ this.mediaManager.selectedVisual?.duration | secondsToTime }}</span>
                            </figure>
                            <button
                                class="videoPoster js-videoPoster sidepanel-playbutton"
                                (click)="MediaLibrarySharedComponent_PlayVideo(this.mediaManager.selectedVisual)"
                            ></button>
                        </div>
                    </div>
                </section>
            </div>
            <div class="formItm addclass mb-2" *ngIf="this.mediaManager.selectedVisual?.visual_type != 'audio'">
                <div class="media-tags createTag_wrapp">
                    <p class="text-gray-2 text-lg mb-2">Keywords</p>
                    <span class="tag mb-2" *ngFor="let tag of this.mediaManager.selectedVisual?.key_terms; index as i"
                        >{{ tag }}
                        <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 12"
                            fill="none"
                            (click)="removeTag(tag)"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0)">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                                    fill="#7A7A7A"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="11.8" height="11.8" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <div class="d-block" [ngClass]="!this.isOwner ? 'd-none' : ''">
                        <div class="tagfield createTag_wrappAdd" [ngClass]="bDisplayAddInputField ? 'd-inline-block' : 'd-none'">
                            <input
                                class="add-Tag"
                                maxlength="32"
                                #AddInputTagElement
                                (keydown.enter)="$event.preventDefault()"
                                onkeypress="return event.charCode != 32"
                                (focusout)="CreateVideoLooknFeelComponent_FocusoutFromInput()"
                                (keydown)="CreateVideoLooknFeelComponent_EnterKeyTermsTag($event.keyCode)"
                                [(ngModel)]="inputValue"
                                [ngModelOptions]="{ standalone: true }"
                                type="text"
                                (change)="CreateVideoLooknFeelComponent_GetInputValue($event)"
                            /><input
                                class="tag-btn"
                                type="button"
                                value="add"
                                [disabled]="!inputValue"
                                (click)="CreateVideoLooknFeelComponent_AddTag()"
                                toggle-class="#createTag_wrapp"
                            />
                        </div>
                        <a
                            *ngIf="!bDisplayAddInputField"
                            href="javascript:void(0)"
                            (click)="CreateVideoLooknFeelComponent_DisplayAddTagInput()"
                            class="text-sm text-primary-4 ms-2 createTag_wrappAddBtn"
                            toggle-class="#createTag_wrapp"
                        >
                            <svg class="me-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.26087 0.391304C6.26087 0.175193 6.08568 0 5.86957 0C5.65345 0 5.47826 0.175193 5.47826 0.391304L5.47826 5.73913H0.391304C0.175193 5.73913 0 5.91432 0 6.13043C0 6.34655 0.175193 6.52174 0.391304 6.52174H5.47826L5.47826 11.6087C5.47826 11.8248 5.65345 12 5.86956 12C6.08568 12 6.26087 11.8248 6.26087 11.6087L6.26087 6.52174H11.6087C11.8248 6.52174 12 6.34655 12 6.13043C12 5.91432 11.8248 5.73913 11.6087 5.73913H6.26087L6.26087 0.391304Z"
                                    fill="#FF8369"
                                />
                            </svg>
                            Add a Keyword
                        </a>
                    </div>
                </div>
            </div>

            <div class="m-0 pb-2">
                <p class="text-gray-2 text-lg fw-bold mb-3">Media Info</p>
                <p class="d-flex cruunt-plan">
                    <span class="text-gray-2 text-xs">Added on</span>
                    <span class="text-gray-2 text-xs fw-bold text-end pe-2">{{
                        this.mediaManager.selectedVisual?.created?.$date | slice : 0 : 10
                    }}</span>
                </p>
                <p class="d-flex cruunt-plan">
                    <span class="text-gray-2 text-xs">File Name</span>
                    <span class="text-gray-2 text-xs fw-bold text-end pe-2 text-truncate">{{
                        this.mediaManager?.selectedVisual != undefined &&
                            this.mediaManager?.selectedVisual?.fpath != '' &&
                            this.mediaManager?.selectedVisual?.fpath.split('/').pop()
                    }}</span>
                </p>
                <p class="d-flex cruunt-plan">
                    <span class="text-gray-2 text-xs">File Size</span>
                    <span class="text-gray-2 text-xs fw-bold text-end pe-2">{{
                        BusinessProfileSidepanelsComponent_ReturnFileSize(this.mediaManager.selectedVisual?.size)
                    }}</span>
                </p>
                <p class="d-flex cruunt-plan">
                    <span class="text-gray-2 text-xs">Media Library</span>
                    <span class="text-gray-2 text-xs fw-bold text-end pe-2 text-truncate" style="word-wrap: break-word">{{
                        this.mediaLibraryDetail.name
                    }}</span>
                </p>
            </div>
        </div>
    </nav>
    <!-- Select Media sidebar Modal end-->

    <!-- Multi Select media  sidebar Modal Start-->
    <nav id="multiselectmedia" class="rightSideBar right-0 sidebar-block" style="width: 400px !important; z-index: 1;">
        <div class="sidebar-header">
            <h6 class="text-gray-2 weight-700"><span class="text-primary-4" style="font-family: poppins;">{{ this.mediaManager.selectedVisuals?.length }}</span> Meida Files Selected</h6>
            <a href="#" class="del-media" data-bs-toggle="modal" data-bs-target="#delMedia-popup">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                        fill="#7A7A7A"
                    />
                </svg>
            </a>
            <div class="closeRightSideBar pt-2" style="top: 18px; right: 15px" (click)="close()">
                <a (click)="MediaLibrarySharedComponent_CloseSidebar()">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_6)">
                            <path
                                d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                                fill="#F2F2F5"
                            />
                            <path
                                d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                                fill="#A7ABB6"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_6">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
        </div>
        <div class="sidebar-content pe-0">

        <div  class="link link-hover mb-3" data-bs-toggle="modal" *ngIf="this.mediaManager.selectedVisuals && this.mediaManager.selectedVisuals.length > 0"
        data-bs-target="#export-fpath-popup" style="color: #333; font-size: 12px; background-color: #eee; width: 113px; padding: 5px; border-radius: 17px">
            <img
                _ngcontent-uha-c285=""
                src="../../../assets/images/svg/download.svg"
                title="copy"
                class="link"
                style="height: 16px; width: 20px"

            />
            Export Paths
        </div>
            <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                <div
                    ngxSlickItem
                    *ngFor="let slide of this.mediaManager.selectedVisuals; let i = index"
                    class="slide slick-slider"
                    style="margin-right: 5px"
                    (click)="MediaLibrarySharedComponent_SelectedImage(i)"
                >
                    <a class="close-remove close-btn" (click)="selectVisual(slide)">
                    </a>
                    <img
                        src="{{ slide.visual_type != 'audio' ? slide.thumbnail || slide.url : this.imageStaticThumb }}"
                        alt=""
                        width="100%"
                    />
                </div>
            </ngx-slick-carousel> -->
            <ul class="d-flex flex-wrap p-0 multiselect fancy-scroll">
            <li *ngFor="let visual of this.mediaManager.selectedVisuals">
                <!-- audio -->
                <section *ngIf="visual.visual_type == 'audio'">
                    <div
                        class="video-outer" style="min-width: 103px !important; border: none;">
                        <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
                            <div id="loader-inner" class="media-loader-inner"></div>
                        </div>

                        <div class="audio_wrapper">
                            <figure class="mb-0" style="border:2px solid #FF8369; border-radius: 3px;">
                                <img [src]="this.imageStaticThumb" alt="audio-line" />
                            </figure>
                        </div>
                        <p style="cursor: pointer" class="font-size truncate-line">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                    </div>
                </section>
                <!-- image -->

                <section *ngIf="visual.visual_type == 'image'">
                    <div
                        class="video-outer"  style="min-width: 103px !important; border: none;">
                        <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
                            <div id="loader-inner" class="media-loader-inner"></div>
                        </div>

                        <div class="video_wrapper video_wrapper_full js-videoWrapper">
                            <figure class="video-baner" style="border:2px solid #FF8369; border-radius: 3px;">
                                <img [src]="visual.thumbnail_signed_url" alt="image" style="object-fit: contain" />
                            </figure>
                        </div>
                        <p style="cursor: pointer" class="font-size truncate-line">
                            {{ returnFileName(visual?.fpath?.split('/').pop())}}
                        </p>
                    </div>
                </section>

                <!-- video -->
                <section *ngIf="visual.visual_type == 'clip' || visual.visual_type == 'video'">
                    <div
                        class="video-outer" style="min-width: 103px !important; border: none;">
                        <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
                            <div id="loader-inner" class="media-loader-inner"></div>
                        </div>

                        <div class="video_wrapper video_wrapper_full js-videoWrapper">
                            <video
                                controls
                                controlsList="nodownload noplaybackrate"
                                class="videoIframe js-videoIframe"
                                [muted]="true"
                                style="width: auto; max-width: max-content; margin: auto"
                                [src]="visual.signed_url"
                                [id]="visual.fpath"
                            ></video>
                            <figure class="video-baner"  style="border:2px solid #FF8369; border-radius: 3px; width: auto; max-width: max-content; margin: auto">
                                <img [src]="visual.thumbnail_signed_url" alt="video1" />
                            </figure>
                            <button class="videoPoster js-videoPoster" style="pointer-events: none;" ></button>
                        </div>

                        <p style="cursor: pointer" class="font-size truncate-line">
                            {{ returnFileName(visual?.fpath?.split('/').pop()) }}
                        </p>
                    </div>
                </section>
                <!-- </nz-spin> -->
            </li>
        </ul>
            <div class="formItm mb-2" style="margin-top: -25px">
                <div *ngIf="this.mediaManager.selectedVisuals?.length" class="media-tags createTag_wrapp pt-2 mt-1">
                    <p class="text-gray-2 text-lg mb-2 ps-1 pt-4">Keywords</p>
                    <span class="tag mb-2" *ngFor="let tag of this.commonTags; let i = index"
                        >{{ tag }}
                        <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 12"
                            fill="none"
                            (click)="CreateVideoLooknFeelComponent_RemoveTag(i, tag)"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0)">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                                    fill="#7A7A7A"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="11.8" height="11.8" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <div class="d-block mt-2" [ngClass]="!this.isOwner ? 'd-none' : ''">
                        <div class="tagfield createTag_wrappAdd" [ngClass]="bDisplayAddInputField ? 'd-inline-block' : 'd-none'">
                            <input
                                class="add-Tag"
                                maxlength="32"
                                #AddInputElement
                                (keydown.enter)="$event.preventDefault()"
                                onkeypress="return event.charCode != 32"
                                (focusout)="CreateVideoLooknFeelComponent_FocusoutFromInput()"
                                (keydown)="CreateVideoLooknFeelComponent_EnterKeyTermsTag($event.keyCode)"
                                [(ngModel)]="inputValue"
                                [ngModelOptions]="{ standalone: true }"
                                type="text"
                                (change)="CreateVideoLooknFeelComponent_GetInputValue($event)"
                            /><input
                                class="tag-btn"
                                type="button"
                                value="add"
                                [disabled]="!inputValue"
                                (click)="CreateVideoLooknFeelComponent_AddTag()"
                                toggle-class="#createTag_wrapp"
                            />
                        </div>
                        <a
                            *ngIf="!bDisplayAddInputField"
                            href="javascript:void(0)"
                            (click)="CreateVideoLooknFeelComponent_DisplayAddTagMultiSelectedInput()"
                            class="text-sm text-primary-4 ms-2 createTag_wrappAddBtn"
                            toggle-class="#createTag_wrapp"
                        >
                            <svg class="me-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.26087 0.391304C6.26087 0.175193 6.08568 0 5.86957 0C5.65345 0 5.47826 0.175193 5.47826 0.391304L5.47826 5.73913H0.391304C0.175193 5.73913 0 5.91432 0 6.13043C0 6.34655 0.175193 6.52174 0.391304 6.52174H5.47826L5.47826 11.6087C5.47826 11.8248 5.65345 12 5.86956 12C6.08568 12 6.26087 11.8248 6.26087 11.6087L6.26087 6.52174H11.6087C11.8248 6.52174 12 6.34655 12 6.13043C12 5.91432 11.8248 5.73913 11.6087 5.73913H6.26087L6.26087 0.391304Z"
                                    fill="#FF8369"
                                />
                            </svg>
                            Add a Keyword
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <!-- Multi Select media sidebar Modal end-->

    <!-- uploading sidebar Modal Start-->
    <nav id="uploadingMedia" class="rightSideBar right-0 sidebar-block uploading-sidebar" style="z-index: 1;">
        <div class="sidebar-header">
            <h6 class="text-gray-2 weight-700">Uploading {{ this.mediaManager.uploadMediaList.length }} items</h6>

            <div class="closeRightSideBar" style="top: 10px" (click)="close()">
                <a (click)="MediaLibrarySharedComponent_CloseSidebar()">
                    <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_6)">
                            <path
                                d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                                fill="#F2F2F5"
                            />
                            <path
                                d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                                fill="#A7ABB6"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_6">
                                <rect width="30" height="30" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
        </div>
        <div class="sidebar-content" style="height: 630px">
            <ul class="pt-2 uploadingList">
                <li
                    *ngFor="let media of this.mediaManager.uploadMediaList"
                    [ngStyle]="{
                        opacity: media.status == 'uploading' ? '30%' : '100%',
                        background:
                            media.status == 'uploaded' ? 'transparent' : media.status == 'error' ? 'rgba(255, 0, 0, 0.1)' : 'transparent'
                    }"
                >
                    <div class="d-flex justify-content-between">
                        <p>
                            <img [src]="this.imageStaticThumb" *ngIf="media.file.type.includes('audio')" />
                            <img [src]="media.uri" *ngIf="media.file.type.includes('image')" />

                            <video
                                style="top: 11px; position: relative; width: 45px; height: 35px; border-radius: 12px; pointer-events: none"
                                *ngIf="media.file.type.includes('video')"
                            >
                                <source [src]="media.uri" type="video/mp4" />
                            </video>
                            <span class="ps-2">{{ returnFileName(media.name) }}</span>
                        </p>
                        <p>
                            <span class="pe-2">{{ BusinessProfileSidepanelsComponent_ReturnFileSize(media.file.size) }}</span>
                            <span class="pe-0" *ngIf="media.status == 'uploading'">
                                <img
                                    class="image-rotate"
                                    src="/assets/images/loading-process-svgrepo-com.svg"
                                    style="width: 15px; height: 15px"
                                />
                            </span>
                            <span class="pe-0" *ngIf="media.status == 'uploaded'">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 48 48"
                                    style="fill: #000000"
                                >
                                    <linearGradient
                                        id="I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1"
                                        x1="9.858"
                                        x2="38.142"
                                        y1="9.858"
                                        y2="38.142"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop offset="0" stop-color="#00C599"></stop>
                                        <stop offset="1" stop-color="#00C599"></stop>
                                    </linearGradient>
                                    <path
                                        fill="url(#I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1)"
                                        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                                    ></path>
                                    <path
                                        d="M32.172,16.172L22,26.344l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l8,8c0.781,0.781,2.047,0.781,2.828,0l13-13c0.781-0.781,0.781-2.047,0-2.828L35,16.172	C34.219,15.391,32.953,15.391,32.172,16.172z"
                                        opacity=".05"
                                    ></path>
                                    <path
                                        d="M20.939,33.061l-8-8c-0.586-0.586-0.586-1.536,0-2.121l1.414-1.414c0.586-0.586,1.536-0.586,2.121,0	L22,27.051l10.525-10.525c0.586-0.586,1.536-0.586,2.121,0l1.414,1.414c0.586,0.586,0.586,1.536,0,2.121l-13,13	C22.475,33.646,21.525,33.646,20.939,33.061z"
                                        opacity=".07"
                                    ></path>
                                    <path
                                        fill="#fff"
                                        d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0	L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414l-13,13	C22.317,33.098,21.683,33.098,21.293,32.707z"
                                    ></path>
                                </svg>
                            </span>
                            <span class="pe-0" *ngIf="media.status == 'error'" (click)="deleteSelectedUploadingMedia(media)">
                                <img src="/assets/images/cross.svg" style="width: 15px; height: 15px" [title]="media.error" /><br />
                            </span>
                        </p>
                    </div>
                    <h6 *ngIf="media.status == 'error'" style="color: red; font-size: 10px; line-height: 1">
                        {{ media.error }}
                    </h6>
                </li>
            </ul>
        </div>
    </nav>
    <!-- uploading sidebar Modal end-->

    <!-- ----------------------------------------------------------- -->
    <!--Select Singal and Multiple Visual and show in sidebar End  -->
    <!-- ----------------------------------------------------------- -->

    <!-- Modal pop-up Start-->
    <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 10000">
        <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Upload media files</h5>
                    <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group files">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-device" role="tabpanel" aria-labelledby="pills-device-tab">
                                <div class="file-drop-area">
                                    <figure>
                                        <svg width="23" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                    fill="#7A7A7A"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect
                                                        width="16.0013"
                                                        height="15.9809"
                                                        fill="white"
                                                        transform="translate(0 0.00958252)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </figure>
                                    <span class="choose-file-button">Upload a File (Device)</span>
                                    <span class="text-line"
                                        >Click to <font class="text-primary-3">browse</font>, or<br />
                                        drag & drop your file here</span
                                    >
                                    <span class="file-message" *ngIf="nFilesToUploadCount">{{ nFilesToUploadCount }} file selected</span>
                                    <input
                                        #uploadFileField
                                        class="file-input"
                                        type="file"
                                        multiple
                                        accept="image/jpg, image/png, image/jpeg, audio/mp3, video/mp4, video/avi"
                                        (change)="MediaLibrarySharedComponent_UploadFiles($event)"
                                    />
                                </div>
                            </div>
                            <ul class="nav mb-3" id="pills-tab" role="tablist">
                                <li class="col nav-item" role="presentation">
                                    <button
                                        (click)="uploadFileField.click()"
                                        class="nav-link"
                                        id="pills-device-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-device"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-device"
                                        aria-selected="true"
                                    >
                                        <img src="../assets/images/business_Profile/device.png" alt="tab-icon" />
                                        <span>My Device</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal pop-up End-->

    <!-- Delete pop-up Start-->
    <div class="modal fade" id="delMedia-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="padding: 1rem !important">
                <div class="modal-body" style="padding: 1rem !important">
                    <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <h5 _ngcontent-web-c627="" class="mb-4">
                        Are you sure you want to delete
                        {{ this.bSelectMultipleVisuals && this.mediaManager.selectedVisuals.length > 1 ? 'these files?' : 'this file' }}
                    </h5>
                    <div _ngcontent-web-c627="" class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                        <div class="col-1 p-0">
                            <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path
                                        d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602
                    16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29
                    26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747
                    3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365
                    14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365
                    12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993
                    7.3043C14.6062 7.3043 15.1095 7.80441 15.1095
                    8.40747V15.7619Z"
                                        fill="#FF8369"
                                    ></path>
                                    <path
                                        d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398
                    1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0
                    18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366
                    2.32Z"
                                        fill="#FF8369"
                                    ></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="28" height="29" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div class="col-11 p-0">
                            <p class="text-sm text-gray-3 pt-1 pb-0">
                                Are you sure you want to delete the selected
                                {{ this.bSelectMultipleVisuals && this.mediaManager.selectedVisuals.length > 1 ? 'files?' : 'file' }} This
                                action cannot be undone.
                            </p>
                        </div>
                    </div>

                    <!-- <a
          href="javascript:void(0)"
          id="sidebarCollapse"
          class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
          data-bs-dismiss="modal"
          >Cancel</a
        > -->
                    <a
                        class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
                        href="javascript:void(0)"
                        data-bs-dismiss="modal"
                        (click)="MediaLibrarySharedComponent_DeleteVisual()"
                        >Delete</a
                    >
                </div>
            </div>
        </div>
    </div>
    <!-- Export Fpaths-->
    <div class="modal fade" id="export-fpath-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="padding: 1rem !important">
                <div class="modal-body" style="padding: 1rem !important">
                    <!-- <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    <h5 _ngcontent-web-c627="" class="mb-2">Export Paths Confirmation</h5>
                    <div _ngcontent-web-c627="" class="p-0 mb-2 g-0 row rounded-1">
                        <!-- <div class="col-1 p-0">
                            <img src='/assets/images/icons/alert-clock.svg' width="28" height="'28">
                        </div> -->
                        <div class="col-12 p-0 pe-4">
                            <p class="text-sm text-gray-3 pt-1 pb-0" style="font-family: poppins;">
                                You are ready to export the paths for your selected media {{ this.bSelectMultipleVisuals && this.mediaManager.selectedVisuals.length > 1 ? 'files' : 'files' }}.<br>A download will start containing these paths once you confirm. <br>Do you want to continue?
                            </p>
                        </div>
                    </div>
                    <a
                        class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
                        href="javascript:void(0)"
                        data-bs-dismiss="modal"
                        (click)="downloadCSV()"
                        >Yes, Download</a
                    >
                    <a href="" data-bs-dismiss="modal" aria-label="Close" class="weight-normal text-gray-3 text-sm py-3">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete pop-up End-->
</div>
