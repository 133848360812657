import { date } from './../models/media';
import { filter } from 'rxjs/operators';
import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { promise, Session } from 'protractor';

import { environment } from '../../environments/environment';
import { meta_info } from '../models/meta_info';
import { aspectRatio, template, templateBlockConfiguration, templateConfig, templatesCategory } from '../models/template';
import { block_args, voice } from '../models/video';
import { cClientService } from '../services/client.service';
import { CommonCallService } from '../services/dataServices/common-call.service';
import { OnboardingManagerService } from './onboarding-manager.service';
import { LoaderService } from '../services/loader.service';
import { map } from 'jquery';

@Injectable({
    providedIn: 'root',
})
export class ConfigManagerService {
    public bDisplayLocales: boolean = true;
    public text_effect_names: string[] = [];
    public transition_names: string[] = [];
    public durations: number[] = [];
    public clip_effect_names: string[] = [];
    public template_config: any[] = [];
    public clearGenerationInterval = false;
    public voices: voice[] = [];
    public sapVideoDurationDiff = 8;
    constructor(public clientService: cClientService, public httpCommon: CommonCallService, private loader: LoaderService) {
        // this.getMetaInfo();
    }
    public Templates: templatesCategory[] = [];
    public metaInfo: meta_info;
    public aspect_ratios: aspectRatio[] = [];
    public templateCategories: any[] = [];
    async getMetaInfo() {
        await this.clientService.getMetaInfo().subscribe(
            (res: any) => {
                //console.log(res);
                this.metaInfo = res as meta_info;
                // this.metaInfo.stripe_enabled = false;
                // console.log(this.metaInfo);
            },
            (err: any) => {
                console.log(err);
            },
        );
    }
    async getVoices(email, session): Promise<unknown> {
        let promise = new Promise((resolve, reject) => {
            // console.log("GET LIST OF VOICES ....");
            this.httpCommon.getVoicelist(email, session).then(
                (res: any) => {
                    this.voices = [];
                    const keys = Object.keys(res);

                    keys.forEach((key, index) => {
                        let vi = new voice();
                        vi.name = key;
                        vi.sample_voice = res[key].sample_voice;
                        vi.gender = res[key].gender;

                        this.voices.push(vi);

                        resolve(res);
                    });
                },
                (error) => {
                    reject('');
                },
            );

            // console.log("step", this.voices);
        });

        return promise;
    }

    public durationVideoMap: Map<string, Map<string, any>> = new Map<string, Map<string, any>>();

    aspectRatioDataMap: Map<string, { templates: { name: string; data: any[] }[]; data: any[] }> = new Map<
        string,
        { templates: { name: string; data: any[] }[]; data: any[] }
    >();

    insertTemplateInaspectRatioMap(aspect: string, templateName: string, options: any) {
        const keyMapObj = this.aspectRatioDataMap.get(aspect);

        if (keyMapObj) {
            let mapValue: { templates: any[]; data: any[] } = this.aspectRatioDataMap.get(aspect);

            if (mapValue.templates.length > 0) {
                let indexRec = mapValue.templates.filter((item) => item.name == templateName);
                if (!indexRec || indexRec.length == 0) {
                    keyMapObj.templates.push({ name: templateName, data: [] });
                }
            } else {
                keyMapObj.templates = [];
                keyMapObj.templates.push({ name: templateName, data: [] });
            }
        } else {
            let dataAspect = {
                templates: [{ name: templateName, data: [] }],
                data: [],
            };

            this.aspectRatioDataMap.set(aspect, dataAspect);
        }
    }

    loadTemplatesSignedUrl(duration, aspect, email, session, selectedCategory = 'Product/Services', selectedTemplate = undefined) {
        return new Promise((resolve, reject) => {
            this.templateCategories = [];
            let fpathMedia = [];

            if (this.durationVideoMap.get('' + duration) && this.durationVideoMap.get('' + duration).get(aspect)) {
                let templateData = this.durationVideoMap.get('' + duration).get(aspect);

                templateData.forEach((element: any) => {
                    if (element.data.thumb.indexOf('no-pic-template') == -1) {
                        element.data.className = 'normal';
                        fpathMedia.push(element.data.thumb);
                        fpathMedia.push(element.data.preview);
                    } else {
                        element.data.className = 'no-pic';
                    }

                    if (element.categories && element.categories.indexOf(selectedCategory) > -1) {
                        this.templateCategories.push(element);
                    }
                });
            }
            this.clientService
                .getThumbBatchSignedURL(email, session, fpathMedia)
                .then((bUrls: any) => {
                    let output = bUrls['output'];
                    this.templateCategories?.forEach((ele) => {
                        ele.data.url = output.filter((a) => a[ele.data.preview])[0][ele.data.preview];
                        ele.data.preview_url = output.filter((a) => a[ele.data.preview])[0][ele.data.preview];
                        ele.data.thumbnail = output.filter((a) => a[ele.data.thumb])[0][ele.data.thumb];
                        if (selectedTemplate) {
                        }
                    });
                    resolve(true);
                    // console.log('templates2----2', this.Templates);
                })
                .catch((err) => {
                    resolve(true);
                });
            //   console.log( " this.templateCategories *********", this.templateCategories);
        });
    }

    capitalizeFirstLetter(str: string): string {
        return str.slice(0, 1).toUpperCase() + str.slice(1);
    }
    findAspectRationOfDuration(duration, aspect) {
        if (this.aspectRatioDataMap.size == 0) {
            return false;
        }
        let aspectRatioValue: any = this.aspectRatioDataMap.get(duration);
        return aspectRatioValue.get(aspect) ? true : false;
    }

    getPreviewOfTemplateByAspectAndDuration(duration, aspectRat, template, options, address_fields = true) {
        let dataTemplate = undefined;
        let templateName = this.capitalizeFirstLetter(template);
        if (
            this.templateMetaDataInfo != null &&
            this.templateMetaDataInfo[templateName] &&
            this.templateMetaDataInfo[templateName]['previews']
        ) {
            this.templateMetaDataInfo[templateName]['previews'].map((element, i) => {
                if (element.aspect_ratio == aspectRat && element.duration == duration) {
                    element.template_name = templateName;
                    dataTemplate = element;
                }
            });
        }

        if (!dataTemplate) {
            let date = {
                aspect_ratio: aspectRat,
                name: templateName,
                template_name: templateName,
                duration: duration,
                address_fields: address_fields,
                preview: '../assets/images/no-pic-template.png',
                thumb: '../assets/images/no-pic-template.png',
                thumbnail: '../assets/images/no-pic-template.png',
                url: '../assets/images/no-pic-template.png',
            };
            dataTemplate = date;
        }

        return dataTemplate;
    }

    insertDurationMap(duration: string, aspect: string, templateName: string = undefined, options: any = undefined, address_fields = true) {
        let keyMapObj = this.durationVideoMap.get(duration);
        // Check Duration key exists
        let templatePreview = this.getPreviewOfTemplateByAspectAndDuration(duration, aspect, templateName, options, address_fields);
        let templateCategories = options && options.categories ? options.categories : [];
        templatePreview.address_fields = address_fields;
        if (keyMapObj) {
            let valueTemplate: Map<string, any> = new Map<string, any>();

            //If asspect added in map
            let aspectMapList = keyMapObj.get(aspect);
            if (aspectMapList) {
                if (aspectMapList.get(templateName)) {
                    let templateDate = aspectMapList.get(templateName)['data'];

                    // templateDate.push({ name: 'sharjeel', val: 2 });
                } else {
                    aspectMapList.set(templateName, { name: templateName, data: templatePreview, categories: templateCategories });
                }
            } else {
                //aspect not added

                valueTemplate.set(templateName, { name: templateName, data: templatePreview, categories: templateCategories });
                keyMapObj.set(aspect, valueTemplate);
            }
        } else {
            let aspectMap: Map<string, any> = new Map<string, any>();
            let valueTemplate: Map<string, any> = new Map<string, any>();

            valueTemplate.set(templateName, { name: templateName, data: templatePreview, categories: templateCategories });
            aspectMap.set(aspect, valueTemplate);
            this.durationVideoMap.set(duration, aspectMap);
        }
    }

    templateMetaDataInfo: any;
    public async getTemplates2(email, session, allowedTemplates: string[]) {
        // console.log(' Template meta info ', allowedTemplates);
        this.Templates = [];
        this.aspect_ratios = [];
        await this.clientService.getTemplates().then((res: any) => {
            this.Templates = [];
            this.loader.hideLoader();
            for (const key in res.template_meta_data['Categories']) {
                let tc = new templatesCategory();
                tc.category = key;

                this.Templates.push(tc);
            }

            // console.log(' Template Meta data ', res.template_meta_data);
            this.aspect_ratios = [];
            this.templateMetaDataInfo = res.template_meta_data;
            // Setting Aspect Ratio - Filtered by Durtion - Template
            for (let keys in res.template_meta_data) {
                let data = res.template_meta_data[keys]['previews'];

                let durationMeta = res.template_meta_data[keys]['aspect_ratios'];
                let categoriesTemplates = res.template_meta_data[keys]['categories'];

                let address_fields = res.template_meta_data[keys].address_fields;
                if (durationMeta != null) {
                    for (let dur in durationMeta) {
                        for (let aspect in durationMeta[dur]) {
                            this.insertDurationMap(
                                dur,
                                durationMeta[dur][aspect],
                                keys,
                                {
                                    categories: categoriesTemplates,
                                    address_fields: address_fields,
                                },
                                address_fields,
                            );
                        }
                    }
                }

                // if (data != null) {

                //   data.map((element, i) => {

                //     // console.log( " element.aspect_ratio ", element.aspect_ratio, keys);
                //     let aspect_ratio = element.aspect_ratio;
                //     this.insertTemplateInaspectRatioMap(aspect_ratio, keys, []);
                //   })

                // }
            }

            // console.log('%c*************************** ', 'color:red');
            // console.log('  -- ', this.aspectRatioDataMap);
            //Load Duration Category Template
            // console.log(' this.durationVideoMap ', this.durationVideoMap);
            let batchUrls = [];

            for (const key in res.template_meta_data) {
                // console.log(key);

                if (key != 'Categories') {
                    if (Object.prototype.hasOwnProperty.call(res.template_meta_data, key)) {
                        let template = res.template_meta_data[key] as template;
                        template.template_name = key;

                        // console.log("allowedTemplates ", allowedTemplates);
                        // allowedTemplates?.forEach((ele) => {
                        //   if (ele == key) {
                        if (template.categories) {
                            // console.log("Added categories ...", template.categories);
                            template.categories.forEach((c) => {
                                this.Templates.forEach((tc) => {
                                    if (tc.category == c) {
                                        if (!tc.templates) {
                                            tc.templates = [];
                                        }

                                        template.previews.forEach((preview) => {
                                            if (preview.preview && !batchUrls.includes(preview.preview)) batchUrls.push(preview.preview);

                                            if (preview.thumb && !batchUrls.includes(preview.thumb)) batchUrls.push(preview.thumb);
                                        });
                                        // console.log(" PUSH CAT INTO TEMPLATE ..");
                                        tc.templates.push(template);
                                    }
                                });
                            });
                        }
                        // console.log(' TC TEMPLATE CALL', this.Templates);
                        //   }
                        // });
                    }
                }
            }
            // console.log(' -----------------------------------');
            this.clientService.getThumbBatchSignedURL(email, session, batchUrls).then((bUrls: any) => {
                // console.log('urls',bUrls);

                let output = bUrls['output'];
                this.Templates?.forEach((ele) => {
                    ele.templates?.forEach((temp) => {
                        temp.previews?.forEach((pr) => {
                            pr.url = output.filter((a) => a[pr.preview])[0][pr.preview];
                            pr.thumbnail = output.filter((a) => a[pr.thumb])[0][pr.thumb];
                        });
                    });
                });

                // console.log('templates2----2', this.Templates);
            });
            // console.log('templates2', this.Templates);
        });
    }

    public async getTemplates(email, session, allowedTemplates: string[]) {
        // console.log('allowed tempaltes', allowedTemplates);
        // console.log(" GET TEMPLATES IS IN USE HERE ....", allowedTemplates);
        this.Templates = [];

        await this.clientService.getVideoTemplates(email, session).then((res: any) => {
            this.Templates = [];
            // console.log(res);
            // console.log("Get Video Template Duration ........", res);
            //this.clip_effect_names = res['clip_effect_names'];
            // this.text_effect_names = res['text_effect_names'];
            this.durations = res['durations'];
            //this.transition_names = res['transition_names'];
            this.template_config = res['template_config'];

            for (const key in res.template_categories) {
                //  console.log(key);
                if (Object.prototype.hasOwnProperty.call(res.template_categories, key)) {
                    let tc = new templatesCategory();
                    tc.category = key;
                    let templates = res.template_categories[key] as template[];
                    tc.templates = [];
                    templates.forEach((e) => {
                        allowedTemplates?.forEach((ele) => {
                            if (ele == e.template_name) {
                                tc.templates.push(e);
                            }
                        });
                    });
                    // console.log(tc.templates);

                    if (tc.templates) {
                        tc.templates.forEach((c: template, index) => {
                            c.second_logo = res['second_logos'][c.template_name];

                            // console.log("Allowed Tmeplates", allowedTemplates);

                            // console.log("Tempalte Found",allowedTemplates.find(x => x === c.template_name));
                            //  if (allowedTemplates.find(x => x === c.template_name)) {
                            c.loadingPreviewUrl = true;
                            this.httpCommon
                                .getSignedURL(email, session, c.preview_path)
                                .then((imgfres) => {
                                    c.loadingPreviewUrl = false;
                                    c.preview_url = imgfres['url'];
                                    c.video_preview_url = imgfres['url'];
                                })
                                .catch((errimg) => {
                                    c.loadingPreviewUrl = false;
                                });

                            c.loadingThumbnailUrl = true;
                            this.httpCommon
                                .getSignedURL(email, session, c.thumb)
                                .then((imgfres) => {
                                    c.loadingThumbnailUrl = false;
                                    c.thumbnail = imgfres['url'];

                                    // console.log(this.Templates);
                                })
                                .catch((errimg) => {
                                    c.loadingThumbnailUrl = false;
                                });
                            c.templateBlockConfiguration = [];

                            this.extractblocks(res, c, 6);
                            this.extractblocks(res, c, 15);
                            this.extractblocks(res, c, 30);
                            this.extractblocks(res, c, 60);

                            //  } else {

                            //  console.log("Before remove,", tc.templates);
                            //   console.log("index,", index);
                            //   tc.templates.splice(index, 1);
                            //   console.log("After remove,", tc.templates);
                            // }
                        });


                    }

                    setTimeout( ()=>{
                        // console.log(" PUSH TEMPLATE INTO ARRAY ...", tc);
                        this.Templates.push(tc);
                    }, 1000);

                }
                // console.log("All templates here ", this.Templates);
            }

            // console.log("Generated Tempaltes", this.Templates);
        });
    }

    private extractblocks(res: templateConfig, c: template, index: number) {
        if (res.template_config[c.template_name][index.toString()]) {
            // console.log("step1",res.template_config[c.template_name]);
            //console.log("step2",res.template_config[c.template_name][index.toString()]);
            // console.log("step3",res.template_config[c.template_name][index.toString()]['blocks']);

            let block = new templateBlockConfiguration();
            block.timeframe = index;
            block.blocks = [];

            res.template_config[c.template_name][index.toString()]['blocks'].forEach((element) => {
                //  console.log('step4',res.block_config);
                // console.log('step5', element);
                // let bc = res.block_config[element['block_name']] as block_args;
                let bc = element as block_args;
                // console.log(bc);
                block.blocks.push(bc);
            });
            c['templateBlockConfiguration'].push(block);
        }
    }

    isDurationSupported(duration, category, templateName): boolean {
        let isSupported = false;

        if (templateName && templateName != '' && category && category != '') {
            this.Templates.forEach((c) => {
                if (c.category == category) {
                    c.templates.forEach((temp) => {
                        if (temp.template_name === templateName) {
                            temp.templateBlockConfiguration.forEach((bc) => {
                                if (bc.timeframe === duration) {
                                    isSupported = true;
                                }
                            });
                        }
                    });
                }
            });
        } else {
            isSupported = true;
        }

        return isSupported;
    }
}
