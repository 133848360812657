import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigManagerService } from '../../../Managers/config-manager.service';
import { FtCreateVideoManagerService } from './../../../Managers/ft-create-video-manager.service';

import { template, templatesCategory } from '../../../../app/models/template';
import { cDataService } from '../../../services/data.service';
import { cEditVideoService } from '../../../services/edit-video.service';
import { cSessionService } from '../../../services/session.service';
import { cTemplateConfigService } from '../../../services/template.service';
import { cWebSocketService } from '../../../services/websocket.service';
import { mediaFile, visual } from './../../../models/media';
import { MediaManagerService } from './../../../Managers/media-manager.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadFileDialogComponent } from 'src/app/shared/components/upload-file-dialog/upload-file-dialog.component';
declare var $: any;

@Component({
    selector: 'app-ft-looknfeel',
    templateUrl: './ft-looknfeel.component.html',
    styleUrls: ['./ft-looknfeel.component.scss'],
})
export class FtLooknfeelComponent implements OnInit, OnDestroy {
    @ViewChild('previewTemplate') previewTemplate: ElementRef;
    @ViewChild('closeModal') closeModal: ElementRef;
    @ViewChild('closeSecondModal') closeSecondModal: ElementRef;
    bDisplayAddInputField: boolean = false;
    fLooknFeelForm: FormGroup;
    lTemplateCategory: any = [];
    sSessionID: string = '';
    sUserEmail: string = '';
    sVideoId;
    oSavedFormData: any;
    inputValue: string = '';
    lKey_terms: any = [];
    lKeyTermsTags: any = [];
    oPrimaryColor: any;
    oFontColor: any;
    sDisplayResponseErrorMessage: string;
    bDisplayErrorMessage: boolean = false;
    lSelectedTemplateCategory: any = [];
    sSelectedTemplateThumbnailURL: string;
    sSelectedTemplatePreviewVideoURL: string;
    oUploadedLogo: any;
    nUploadedLogoSize: number = 0;
    sUploadedLogoName: string = 'No file choosen.';
    bCreateVideoMode: boolean = true;
    bDisplayUploadLogoSection: boolean = true;
    oLogoDetails: any;
    sDisableEditModeToggle: string = 'false';
    bSendRequestInBackground: boolean = true;

    @ViewChild('AddInputTagElement') AddInputTagElement: ElementRef;
    oTemplateConfigurations: any = [];
    bSecondLogo: any;
    nUploadedSecondLogoSize: any;
    oUploadedSecondLogo: any;
    sUploadedSecondLogoName: any;
    constructor(
        private oWebSocketService: cWebSocketService,

        private oTemplateConfigService: cTemplateConfigService,
        private oFormBuilder: FormBuilder,
        private oRoutes: Router,
        private mediaManger: MediaManagerService,
        private oSessionService: cSessionService,
        public videoManger: FtCreateVideoManagerService,
        public configManager: ConfigManagerService,
        private oEditVideoService: cEditVideoService,
        private dialog: MatDialog,
    ) { }
    sub: Subscription;
    sub2: Subscription;
    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        console.log('Back button pressed');
        event.preventDefault();
    }

    ngOnInit() {
        this.sub2 = this.videoManger.previousSubmit.subscribe((emit) => {
            this.oRoutes.navigateByUrl('/ft/overview');
        });
        this.sub = this.videoManger.submitForm.subscribe((emit) => {
            this.save(emit);
        });

        this.sUserEmail = this.oSessionService.cSessionService_GetGuestEmail();
        this.sSessionID = this.oSessionService.cSessionService_GetGuestSession();
        this.CreateVideoLooknFeelComponent_InitializeJqueryCode();
        // console.log(this.videoManger.selectedVideo);
        const __this = this;
        setTimeout(() => {
            if (__this.videoManger.selectedVideo.template_category) {
                __this.configManager.Templates.forEach((e) => {
                    if (e.category == __this.videoManger.selectedVideo.template_category) {
                        __this.selectedCategory = e;
                    }
                });
            } else {
                __this.selectedCategory = __this.configManager.Templates[0];
                console.log('Selected Category ...', __this.selectCategory);
                __this.videoManger.selectedVideo.template_category = __this.configManager.Templates[0].category;
            }
            __this.selectCategory(__this.selectedCategory);
        }, 1000);

        if (this.videoManger.selectedVideo.template_name) {
            this.templates.forEach((e) => {
                if (e.template_name == this.videoManger.selectedVideo.template_name) {
                    this.selectedTemplate = e;
                    this.videoManger.changeTemplate(e);
                    this.videoManger.changeTemplateForClip(e);
                    this.videoManger.selectedVideo.template_name = e.template_name;
                }
            });
        } else {
            this.selectedTemplate = this.templates[0];
            this.videoManger.changeTemplate(this.selectedTemplate);
            this.videoManger.changeTemplateForClip(this.selectedTemplate);
        }

        this.lKey_terms = this.videoManger.selectedVideo.key_terms;

        this.oPrimaryColor = this.videoManger.selectedVideo.color;
        this.oFontColor = this.videoManger.selectedVideo.font_color;

        if (this.videoManger.selectedVideo.logo_img) {
            this.bDisplayUploadLogoSection = true;
            console.log('logi image look and feel url', this.videoManger.selectedVideo.logo_img_url);
            this.oUploadedLogo = { url: this.videoManger.selectedVideo.logo_img_url };
        }
    }

    public selectedCategory: templatesCategory;
    public selectedTemplate: template;
    public templates: template[] = [];
    allowedTemplates = ['Bold', 'Clean', 'Bright'];
    selectCategory(category: templatesCategory) {
        // console.log(category);
        this.selectedCategory = category;
        this.templates = [];
        this.selectedCategory?.templates?.forEach((e) => {
            e.previews.forEach((pr: any) => {
                if (
                    pr.duration === this.videoManger.selectedVideo.duration &&
                    pr.aspect_ratio === this.videoManger.selectedVideo.aspect_ratio
                ) {
                    e.preview_url = pr.url;
                    e.thumbnail = pr.thumbnail;
                    // console.log("Template ", e);
                    if (this.allowedTemplates.indexOf(e.template_name) > -1) {
                        this.templates.push(e);
                    }
                }
            });
        });
        this.selectedTemplate = this.templates[0];
        this.videoManger.changeTemplate(this.selectedTemplate);
        this.videoManger.changeTemplateForClip(this.selectedTemplate);
    }

    selectTemplate(template: template) {
        console.log(template);
        this.selectedTemplate = template;

        this.videoManger.changeTemplate(template);
        this.videoManger.changeTemplateForClip(template);

        this.bSecondLogo = this.selectedTemplate.second_logo;
    }

    save(message) {
        this.updateVideoLocally();
        this.videoManger.selectedVideo.step = "looknfeel";
        this.videoManger
            .createVideo(this.videoManger.selectedVideo)
            .then((res) => {
                console.log('lookres', res);

                this.oRoutes.navigateByUrl('/ft/content');
            })
            .catch((err) => { });
    }
    private updateVideoLocally() {
        this.videoManger.selectedVideo.template_category = this.selectedCategory.category;
        this.videoManger.selectedVideo.key_terms = this.lKey_terms;
        this.videoManger.selectedVideo.template_name = this.selectedTemplate.template_name;
        this.videoManger.selectedVideo.logo_img = this.CreateVideoLooknFeelComponent_ReturnLogo();
        this.videoManger.selectedVideo.second_logo_img = this.CreateVideoLooknFeelComponent_ReturnSecondLogo();
        this.videoManger.selectedVideo.logo_img_url = this.oUploadedLogo?.url;
    }

    CreateVideoLooknFeelComponent_UpdateVideo(bUpdateVideo: boolean, bIncommingSendRequestInBackground: boolean) {
        console.log('Update video ==> ', bUpdateVideo);
        this.bSendRequestInBackground = bIncommingSendRequestInBackground;
        if (bUpdateVideo) {
            this.oSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
            const oEditVideoForm = this.oEditVideoService.EditVideoService_ReturnCombinedPayload();
            console.log('Updated Video Request : ==> ', oEditVideoForm);
            this.oWebSocketService.WebSocket_SendMessage('user_video_request', JSON.stringify(oEditVideoForm));
        }
    }
    PreviewTemplate() {
        this.previewTemplate.nativeElement.play();
    }
    ngOnDestroy() {
        if (this.sub2) this.sub2.unsubscribe();
        if (this.sub) this.sub.unsubscribe();

        if (this.videoManger.isEditMode) {
            this.updateVideoLocally();
        }
    }

    CreateVideoLooknFeelComponent_InitializeLooknFeelForm() {
        this.fLooknFeelForm = this.oFormBuilder.group({
            session_id: this.sSessionID,
            email: this.sUserEmail,
            video_id: this.sVideoId,
            template_category: [this.lTemplateCategory[0] ? this.lTemplateCategory[0] : 'Sales/Events', [Validators.required]],
            key_terms: [this.lKeyTermsTags],
            template_name: [''],
            color: [''],
            font_color: [''],
            oUploadedLogo: [''],
            nUploadedLogoSize: [''],
            sUploadedLogoName: [this.sUploadedLogoName],
            oUploadedSecondLogo: [''],
            nUploadedSecondLogoSize: [''],
            sUploadedSecondLogoName: [this.sUploadedSecondLogoName],
            bDisplayUploadLogoSection: [this.bDisplayUploadLogoSection],
            bSecondLogo: [this.bSecondLogo],
        });
        let lRemoveDuplicates = new Set(this.lKeyTermsTags);
        this.lKey_terms = [...lRemoveDuplicates];
        this.CreateVideoLooknFeelComponent_SelectTemplateCategory();
    }

    CreateVideoLooknFeelComponent_LoadSelectedTemplate(oIncommingTemplate) {
        this.sSelectedTemplateThumbnailURL = oIncommingTemplate.sThumbnail;
        this.sSelectedTemplatePreviewVideoURL = oIncommingTemplate.sURL;
    }
    //Reset text overlays
    CreateVideoLooknFeelComponent_CheckForResetTextOverlays() {
        this.fLooknFeelForm.get('template_name').valueChanges.subscribe((template_name) => {
            console.log('Template changed : ==> ', template_name);
            this.bSecondLogo = this.oTemplateConfigurations[template_name];
            let lsLooknFeelForm = this.oSessionService.cSessionService_GetLooknFeelForm();
            if (lsLooknFeelForm) {
                let lsContentForm = this.oSessionService.cSessionService_GetContentForm();
                if (lsContentForm) {
                    this.oSessionService.cSessionService_SetResetTextOverlayCheck(true);
                    this.sDisableEditModeToggle = 'true';
                    const nSelectedDuraction = this.oSessionService.cSessionService_GetOverviewForm().duration;
                    this.oTemplateConfigService.TemplateConfigService_UpdateTemplateBlocks(
                        lsContentForm.lBlockConfig,
                        nSelectedDuraction,
                        template_name,
                    );
                    console.log('CreateVideoLooknFeelComponent_CheckForResetTextOverlays : Reset textOverlays ==> ', true);
                }
            }
        });
    }
    CreateVideoLooknFeelComponent_SelectTemplateCategory() {
        let sSelectedTemplateCategory = this.fLooknFeelForm.get('template_category').value;
        let oTemplateCategories = JSON.parse(this.oSessionService.cSessionService_GetTemplatesConfiguration()).template_categories;
        //get thumbnails
        this.oTemplateConfigService
            .TemplateConfigService_FetchTemplateCategories(this.fLooknFeelForm.get('template_category').value)
            .then((result) => {
                this.lSelectedTemplateCategory = result;
                console.log(
                    'CreateVideoLooknFeelComponent_SelectTemplateCategory : SELECTED TEMPLATE ==> ',
                    this.lSelectedTemplateCategory,
                );
                // by default first template name will be selected
                this.CreateVideoLooknFeelComponent_LoadSelectedTemplate(this.lSelectedTemplateCategory[0]);
            });
        var element = document.getElementById('TemplateThumbnail');
        element.classList.remove('videoWrapperActive');
    }
    CreateVideoLooknFeelComponent_LoadSelectedTemplateCategory(sIncommingtemplateCategory: string, sIncommingTemplateName: string) {
        this.oTemplateConfigService.TemplateConfigService_FetchTemplateCategories(sIncommingtemplateCategory).then((result) => {
            this.lSelectedTemplateCategory = result;
            console.log('CreateVideoLooknFeelComponent_SelectTemplateCategory : SELECTED TEMPLATE ==> ', this.lSelectedTemplateCategory);
            let nSelectedTemplateNameIndex: number = this.lSelectedTemplateCategory.findIndex(
                (template) => template.sName === sIncommingTemplateName,
            );
            if (nSelectedTemplateNameIndex == -1) nSelectedTemplateNameIndex = 0;
            this.sSelectedTemplateThumbnailURL = this.lSelectedTemplateCategory[nSelectedTemplateNameIndex].sThumbnail;
            this.sSelectedTemplatePreviewVideoURL = this.lSelectedTemplateCategory[nSelectedTemplateNameIndex].sURL;
        });
        var element = document.getElementById('TemplateThumbnail');
        element.classList.remove('videoWrapperActive');
    }
    CreateVideoLooknFeelComponent_SendLooknFeelFormRequest(bIncommingSendRequestInBackground: boolean) {
        this.bSendRequestInBackground = bIncommingSendRequestInBackground;
        let oLooknFeelPayload = {
            session_id: this.sSessionID,
            email: this.sUserEmail,
            video_id: this.sVideoId,
            template_category: this.fLooknFeelForm.get('template_category').value,
            template_name: this.fLooknFeelForm.get('template_name').value,
            color: this.fLooknFeelForm.get('color').value,
            font_color: this.fLooknFeelForm.get('font_color').value,
            logo_img: this.CreateVideoLooknFeelComponent_ReturnLogo(),
            second_logo_img: this.CreateVideoLooknFeelComponent_ReturnSecondLogo(),
        };
        if (oLooknFeelPayload.second_logo_img == '' || oLooknFeelPayload.second_logo_img == undefined) {
            delete oLooknFeelPayload.second_logo_img;
        }
        console.log('CreateVideoLooknFeelComponent_SendLooknFeelFormRequest : LooknFeel Request => ', oLooknFeelPayload);
        this.oWebSocketService.WebSocket_SendMessage('user_video_request', JSON.stringify(oLooknFeelPayload));
    }
    CreateVideoLooknFeelComponent_HandleOverviewResponse = (InComingMessage) => {
        if (JSON.parse(InComingMessage)['errorMessage']) {
            this.sDisplayResponseErrorMessage = 'An error occurred while creating video. Please try again.';
            console.log('CreateVideoOverviewComponent_HandleLooknFeelComponentResponse : Request Error ==>', InComingMessage);
            $('#error-popup').modal('show');
            this.bDisplayErrorMessage = true;
            setTimeout(() => {
                this.bDisplayErrorMessage = false;
            }, 5000);
        } else {
            const oResponse = JSON.parse(InComingMessage);
            this.oSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
            console.log('CreateVideoOverviewComponent_HandleLooknFeelComponentResponse : Request result response ==> ', oResponse);

            this.oRoutes.navigateByUrl('/ft/content');
        }
    };
    CreateVideoLooknFeelComponent_FocusoutFromInput() {
        if (this.inputValue !== '') {
            if (!this.lKey_terms) this.lKey_terms = [];
            this.lKey_terms.push(this.inputValue);
        }
        setTimeout(() => {
            this.bDisplayAddInputField = false;
        }, 100);
    }
    CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInputField() {
        this.bDisplayAddInputField = false;
        if (!this.lKey_terms) this.lKey_terms = [];

        this.lKey_terms.push(this.inputValue);

        this.inputValue = '';
        console.log('key ', this.lKey_terms);
    }
    CreateVideoLooknFeelComponent_EnterKeyTermsTag(keycode) {
        if ((keycode == 13 && this.inputValue != '') || (keycode == 188 && this.inputValue != '')) {
            this.CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInputField();
        }
    }
    CreateVideoLooknFeelComponent_RemoveTagListElements(index) {
        this.lKey_terms.splice(index, 1);
    }
    CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInput() {
        this.inputValue = '';
        setTimeout(() => this.AddInputTagElement.nativeElement.focus());
        this.bDisplayAddInputField = true;
    }
    CreateVideoLooknFeelComponent_GetInputValue(event) {
        this.inputValue = event.target.value;
        console.log('Input Value ==>', this.inputValue);
    }
    async CreateVideoLooknFeelComponent_UploadedLogoFile(event) {
        let file = event.target.files[0];
        this.nUploadedLogoSize = file.size;

        let media = new mediaFile();
        media.file = file;
        media.name = file.name;

        this.mediaManger
            .uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionID, media)
            .then((res: visual) => {
                this.oUploadedLogo = res;
                this.sUploadedLogoName = file.name;
                this.closeModal.nativeElement.click();
            })
            .catch((error) => {
                this.oUploadedLogo = null;
                this.sDisplayResponseErrorMessage = error.errorMessage;
                this.bDisplayErrorMessage = true;
                this.closeModal.nativeElement.click();
                setTimeout(() => {
                    this.bDisplayErrorMessage = false;
                }, 5000);
            });
    }

    async CreateVideoLooknFeelComponent_UploadSecondaryLogoFile(event) {
        let file = event.target.files[0];
        this.nUploadedSecondLogoSize = file.size;

        let media = new mediaFile();
        media.file = file;
        media.name = file.name;

        this.mediaManger
            .uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionID, media)
            .then((res) => {
                console.log('CreateVideoLooknFeelComponent_UploadSecondaryLogoFile : Response ==>', res);
                this.oUploadedSecondLogo = res;
                this.sUploadedSecondLogoName = file.name;
                this.closeSecondModal.nativeElement.click();
            })
            .catch((error) => {
                this.oUploadedSecondLogo = null;
                this.sDisplayResponseErrorMessage = error.error.errorMessage;
                this.bDisplayErrorMessage = true;
                this.closeModal.nativeElement.click();
                setTimeout(() => {
                    this.bDisplayErrorMessage = false;
                }, 5000);
            });
    }

    CreateVideoLooknFeelComponent_GetPrimaryColor(event) {
        const primaryColor = event.target.value;
        console.log('Primary Color ==>', primaryColor);
        this.oPrimaryColor = primaryColor;
    }
    CreateVideoLooknFeelComponent_GetFontColor(event) {
        const fontColor = event.target.value;
        console.log('Font Color ==>', fontColor);
        this.oFontColor = fontColor;
    }
    CreateVideoLooknFeelComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    CreateVideoLooknFeelComponent_ToggleLogoSection(event: any) {
        let value = event.target.value;
        console.log('logo', value);
        if (value === 'upload-logo') {
            this.bDisplayUploadLogoSection = true;
        } else {
            this.bDisplayUploadLogoSection = false;
        }
        this.fLooknFeelForm.patchValue({
            bDisplayUploadLogoSection: this.bDisplayUploadLogoSection,
        });
    }
    CreateVideoLooknFeelComponent_ReturnLogo() {
        let logo_path;
        if (this.bDisplayUploadLogoSection) {
            logo_path = this.oUploadedLogo ? this.oUploadedLogo.visual_path : '';
        }
        return logo_path;
    }
    CreateVideoLooknFeelComponent_ReturnSecondLogo() {
        let logo_path;
        if (this.bSecondLogo) {
            logo_path = this.oUploadedSecondLogo ? this.oUploadedSecondLogo.visual_path : '';
        }
        return logo_path;
    }
    CreateVideoLooknFeelComponent_InitializeJqueryCode() {
        let chatScript = document.createElement('script');
        chatScript.type = 'text/javascript';
        chatScript.text = `
    $(document).ready(function() {
      $(window).keydown(function(event){
        if(event.keyCode == 13) {
          event.preventDefault();
          return false;
        }
      });
    });
    Coloris({
      el: '.coloris',
      swatches: [
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#00b4d8',
          '#48cae4',
      ]
  });`;
        document.body.appendChild(chatScript);
    }

    openFileUploadModal() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '100wv';
        dialogConfig.height = '100vh';
        dialogConfig.panelClass = 'pronto-dialog';
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
            uploadFor: 'image_video_logo',
            multipleUpload: false,
            dropBoxText: '',
            heading: 'Upload a Logo',
        };

        let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

        dialogModal.afterClosed().subscribe((data: any) => {
            if (data?.status == 'success' || data?.type == 'success') {
                console.log('Success called');
                const res = data.result[0];
                this.oUploadedLogo = res;
                this.videoManger.selectedVideo.logo_img_url = this.oUploadedLogo?.url;
                console.log(this.oUploadedLogo);
                this.sUploadedLogoName = res.name;
            } else {
            }
        });
    }
}
